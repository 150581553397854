import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { chainFetches } from '../../service';
import { AppDispatch } from '../configureStore';
import { logout } from './configSlice';

export interface Chain {
	id?: number;
	logoUrl: string;
	label: string;
	name: string;
	chainId: number;
}

export interface ChainState {
	chains: Chain[];
	selectedChain: Chain | null;
}

const chainInitialState: ChainState = {
	chains: [],
	selectedChain: null,
};

export const chainSlice = createSlice({
	name: 'chain',
	initialState: chainInitialState,
	reducers: {
		add: (state, action: PayloadAction<Chain>) => {
			state.chains.push(action.payload);
		},
		list: (state, action: PayloadAction<Chain[]>) => {
			state.chains = action.payload;
		},
		update: (state, action: PayloadAction<Chain>) => {
			for (const index in state.chains) {
				if (state.chains[index].id === action.payload.id) {
					state.chains[index] = action.payload;
				}
			}
		},
		deleteChain: (state, action: PayloadAction<number>) => {
			state.chains = state.chains.filter((t) => t.id !== action.payload);
		},
		selectChain: (state, action: PayloadAction<Chain>) => {
			state.selectedChain = action.payload;
		},
	},
});

export const initChains = () => async (dispatch: AppDispatch) => {
	const listReponse = await chainFetches.listFetch();

	if (listReponse.status === 403) {
		await dispatch(logout());
		return;
	}
	const data = await listReponse.json();

	if (data.status === true) {
		await dispatch(list(data.data));
	}
};

// Action creators are generated for each case reducer function
export const { add, list, update, deleteChain, selectChain } = chainSlice.actions;

export default chainSlice.reducer;
