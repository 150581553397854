import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button } from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from 'react-typesafe-routes';
import { FundingRoundsTable } from '../../components';
import Header from '../../components/global/Header';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { initChains } from '../../redux/features/chainSlice';
import { initFundingRounds } from '../../redux/features/fundingRoundSlice';
import { initSupportedTokens } from '../../redux/features/supportedTokenSlice';
import { router } from '../../Router';

export function ListFundingRoundsPage(props: any) {
	const history = useHistory();
	const fundingRoundsList = useSelector((state: RootState) => state.fundingRoundReducer.fundingRounds);
	const supportedTokensList = useSelector((state: RootState) => state.supportedTokenReducer.supportedTokens);
	const chainsList = useSelector((state: RootState) => state.chainReducer.chains);
	const selectedFeaturedProject = useSelector(
		(state: RootState) => state.featuredProjectReducer.selectedFeaturedProject
	);
	const { id } = useRouteParams(router.getFeaturedProject);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			if (
				(fundingRoundsList === null || fundingRoundsList === undefined || fundingRoundsList.length === 0) &&
				id
			) {
				dispatch(initFundingRounds(id));
			}
			if (supportedTokensList === null || supportedTokensList === undefined || supportedTokensList.length === 0) {
				dispatch(initSupportedTokens());
			}
			if (chainsList === null || chainsList === undefined || chainsList.length === 0) {
				dispatch(initChains());
			}
		}
	}, [history]);

	const handleRefresh = () => {
		if (id) {
			dispatch(initFundingRounds(id));
		}
	};

	return (
		<div>
			<Box display={'flex'} justifyContent="flex-start" gap="2px">
				<Header title="FUNDING ROUNDS LIST" subtitle={`Selected Project - ${selectedFeaturedProject?.name}`} />
				<Button
					variant="contained"
					startIcon={<RefreshIcon />}
					onClick={handleRefresh}
					color="secondary"
					size="large"
					sx={{ alignSelf: 'flex-start' }}
				>
					Refresh
				</Button>
				<Button
					variant="contained"
					startIcon={<AddIcon />}
					color="secondary"
					size="large"
					sx={{ alignSelf: 'flex-start' }}
					onClick={() =>
						history.push(router.addFundingRound({ featuredProjectId: selectedFeaturedProject?.id }).$)
					}
				>
					Add
				</Button>
			</Box>
			<Box m="20px 0 0 0" height="73vh" className="ag-theme-alpine">
				<FundingRoundsTable />
			</Box>
		</div>
	);
}
