import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CurrentAdminUserState {
	id?: string;
	email?: string;
	role: string;
}

const currentAdminUserInitialState: CurrentAdminUserState = {
	id: '',
	email: '',
	role: '',
};

export const currentAdminUserSlice = createSlice({
	name: 'currentAdminUser',
	initialState: currentAdminUserInitialState,
	reducers: {
		setCurrentAdminUser: (state, action: PayloadAction<CurrentAdminUserState>) => {
			state.id = action.payload.id;
			state.email = action.payload.email;
			state.role = action.payload.role;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setCurrentAdminUser } = currentAdminUserSlice.actions;

export default currentAdminUserSlice.reducer;
