import { Configuration } from '../config/Config';
import { WalletService } from './url';

export const walletFetches = {
	getTreasuryWalletDataFetch: async (blockchainLabel: string) => {
		const response = await fetch(`${Configuration.baseUrl}${WalletService.GET_TREASURY_WALLET_DATA}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				blockchainLabel,
			}),
		});
		return await response;
	},

	getCompanyWalletsDataFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${WalletService.GET_COMPANY_WALLETS_DATA}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},
};
