import { Autocomplete, Box, Button, Divider, TextField, Typography, useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { router } from '../../Router';
import Header from '../../components/global/Header';
import { Configuration } from '../../config/Config';
import { useDispatch } from '../../hooks/hooks';
import { logout } from '../../redux/features/configSlice';
import { FeaturedProject, FeaturedProjectData, TeamMember, add } from '../../redux/features/featuredProjectSlice';
import { featuredProjectFetches } from '../../service';

export function AddFeaturedProjectPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const dispatch = useDispatch();

	// set server, validation errors
	const [serverErrorMessage, setServerErrorMessage] = React.useState('');

	// formik initial values
	const formDataInitialValues: FeaturedProjectData = {
		featuredProject: {
			name: '',
			projectOverview: '',
			status: 'in progress',
			isVisible: false,
			isPresentation: false,
			coinSymbol: '',
			websiteUrl: '',
			twitterUrl: '',
			discordUrl: '',
			youtubeUrl: '',
			telegramURl: '',
			mediumUrl: '',
			type: undefined,
			urlName: '',
			showOffUrl: '',
		} as FeaturedProject,
		teamMembers: [
			{
				name: '',
				position: '',
				description: '',
				photoUrl: '',
				linkedInUrl: '',
				twitterUrl: '',
				telegramUrl: '',
			} as TeamMember,
		] as TeamMember[],
	};

	React.useEffect(() => {
		// verify if logged in
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	// handle adding new featured project
	const handleAddFormSubmit = async (values: FeaturedProjectData) => {
		const featuredProjectDataToBackend = {
			...values,
		} as FeaturedProjectData;

		const addResponse = await featuredProjectFetches.addFetch(featuredProjectDataToBackend);

		if (addResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await addResponse.json();

		if (data.status === true) {
			// get created feature project from response
			const featuredProjectToAddToStore = data.data;
			dispatch(add(featuredProjectToAddToStore));

			// go back to featured project list
			history.push(router.listFeaturedProjects().$);
		} else {
			setServerErrorMessage(data.error.value);
		}
	};

	// formik validation schema
	const formDataValidationSchema = yup.object().shape({
		featuredProject: yup.object().shape({
			name: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			projectOverview: yup
				.string()
				.min(4, 'must be at least 4 characters long')
				.max(255, 'maximum characters 255')
				.required('required'),
			status: yup.string().required('required'),
			isVisible: yup.boolean().required('required'),
			isPresentation: yup.boolean().required('required'),
			coinSymbol: yup.string().optional(),
			websiteUrl: yup.string().url('Must be a valid URL').optional(),
			twitterUrl: yup.string().url('Must be a valid URL').optional(),
			discordUrl: yup.string().url('Must be a valid URL').optional(),
			youtubeUrl: yup.string().url('Must be a valid URL').optional(),
			telegramURl: yup.string().url('Must be a valid URL').optional(),
			mediumUrl: yup.string().url('Must be a valid URL').optional(),
			type: yup.string().optional(),
			urlName: yup
				.string()
				.matches(/^[a-zA-Z0-9]+$/, 'String must contain only letters and numbers')
				.required('required'),
			showOffUrl: yup.string().url('Must be a valid URL').optional(),
		}),
		teamMembers: yup.array().of(
			yup.object().shape({
				name: yup.string().min(4, 'must be at least 4 characters long').required('required'),
				position: yup.string().min(3, 'must be at least 3 characters long').required('required'),
				description: yup
					.string()
					.min(4, 'must be at least 4 characters long')
					.max(255, 'maximum characters 255')
					.required('required'),
				//photoUrl: yup.string().url('Must be a valid URL').optional(),
				linkedInUrl: yup.string().url('Must be a valid URL').optional(),
				twitterUrl: yup.string().url('Must be a valid URL').optional(),
				telegramUrl: yup.string().url('Must be a valid URL').optional(),
			})
		),
	});

	const addNewTeamMember = (values: any, setFieldValue: any) => {
		setFieldValue('teamMembers', [
			...values.teamMembers,
			{ name: '', position: '', description: '', photoUrl: '', linkedInUrl: '', twitterUrl: '', telegramUrl: '' },
		]);
	};

	return (
		<Box m="20px">
			<Header title="ADD FEATURED PROJECT" subtitle="Create a New Featured Project" />
			<Formik
				onSubmit={handleAddFormSubmit}
				initialValues={formDataInitialValues}
				validationSchema={formDataValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						{/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Featured Project Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.name}
								name="featuredProject.name"
								error={!!touched.featuredProject?.name && !!errors.featuredProject?.name}
								helperText={touched.featuredProject?.name && errors.featuredProject?.name}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Coin Symbol"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.coinSymbol}
								name="featuredProject.coinSymbol"
								error={!!touched.featuredProject?.coinSymbol && !!errors.featuredProject?.coinSymbol}
								helperText={touched.featuredProject?.coinSymbol && errors.featuredProject?.coinSymbol}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Project Overview"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.projectOverview}
								name="featuredProject.projectOverview"
								error={
									!!touched.featuredProject?.projectOverview &&
									!!errors.featuredProject?.projectOverview
								}
								helperText={
									touched.featuredProject?.projectOverview && errors.featuredProject?.projectOverview
								}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Url Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.urlName}
								name="featuredProject.urlName"
								error={!!touched.featuredProject?.urlName && !!errors.featuredProject?.urlName}
								helperText={touched.featuredProject?.urlName && errors.featuredProject?.urlName}
								sx={{ gridColumn: 'span 2' }}
								multiline
							/>
							<Autocomplete
								options={Configuration.typeFeaturedProject}
								disableClearable
								selectOnFocus
								clearOnBlur
								onChange={(e, value) => setFieldValue('featuredProject.type', value)}
								autoHighlight
								sx={{ gridColumn: 'span 2' }}
								getOptionLabel={(option) => option}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant="filled"
										type="text"
										onBlur={handleBlur}
										label="Type"
										name="featuredProject.type"
										error={!!touched.featuredProject?.type && !!errors.featuredProject?.type}
										helperText={touched.featuredProject?.type && errors.featuredProject?.type}
									/>
								)}
							/>
							<Divider sx={{ gridColumn: 'span 4' }} />
							<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
								Social links
							</Typography>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Show Off Url"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.showOffUrl}
								name="featuredProject.showOffUrl"
								error={!!touched.featuredProject?.showOffUrl && !!errors.featuredProject?.showOffUrl}
								helperText={touched.featuredProject?.showOffUrl && errors.featuredProject?.showOffUrl}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Website Url"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.websiteUrl}
								name="featuredProject.websiteUrl"
								error={!!touched.featuredProject?.websiteUrl && !!errors.featuredProject?.websiteUrl}
								helperText={touched.featuredProject?.websiteUrl && errors.featuredProject?.websiteUrl}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Twitter Url"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.twitterUrl}
								name="featuredProject.twitterUrl"
								error={!!touched.featuredProject?.twitterUrl && !!errors.featuredProject?.twitterUrl}
								helperText={touched.featuredProject?.twitterUrl && errors.featuredProject?.twitterUrl}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Discord Url"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.discordUrl}
								name="featuredProject.discordUrl"
								error={!!touched.featuredProject?.discordUrl && !!errors.featuredProject?.discordUrl}
								helperText={touched.featuredProject?.discordUrl && errors.featuredProject?.discordUrl}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Youtube Url"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.youtubeUrl}
								name="featuredProject.youtubeUrl"
								error={!!touched.featuredProject?.youtubeUrl && !!errors.featuredProject?.youtubeUrl}
								helperText={touched.featuredProject?.youtubeUrl && errors.featuredProject?.youtubeUrl}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Telegram Url"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.telegramURl}
								name="featuredProject.telegramURl"
								error={!!touched.featuredProject?.telegramURl && !!errors.featuredProject?.telegramURl}
								helperText={touched.featuredProject?.telegramURl && errors.featuredProject?.telegramURl}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Medium Url"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.featuredProject.mediumUrl}
								name="featuredProject.mediumUrl"
								error={!!touched.featuredProject?.mediumUrl && !!errors.featuredProject?.mediumUrl}
								helperText={touched.featuredProject?.mediumUrl && errors.featuredProject?.mediumUrl}
								sx={{ gridColumn: 'span 2' }}
							/>
							<Divider sx={{ gridColumn: 'span 4' }} />
							<Box sx={{ gridColumn: 'span 4' }} display="flex" justifyItems="flex-start" gap="20px">
								<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
									Team Members
								</Typography>
								<Button
									color="secondary"
									variant="contained"
									onClick={() => addNewTeamMember(values, setFieldValue)}
								>
									Add Team Member
								</Button>
							</Box>
							{values.teamMembers.map((teamMember: TeamMember, index) => {
								return (
									<Box
										key={index}
										mt="5px"
										display="grid"
										gap="30px"
										gridTemplateColumns="repeat(4,minmax(0,1fr))"
										sx={{
											'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
										}}
									>
										<TextField
											label="Team Member Name"
											type="text"
											name={`teamMembers.${index}.name`}
											variant="filled"
											onBlur={handleBlur}
											onChange={handleChange}
											value={teamMember.name}
											error={
												!!(touched.teamMembers && touched.teamMembers[index]?.name) &&
												!!(errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).name
													? true
													: false)
											}
											helperText={
												touched.teamMembers &&
												touched.teamMembers[index]?.name &&
												errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).name
											}
											fullWidth
											sx={{ gridColumn: 'span 4' }}
											multiline
										/>
										<TextField
											label="Position"
											name={`teamMembers.${index}.position`}
											onChange={handleChange}
											onBlur={handleBlur}
											type="text"
											variant="filled"
											value={teamMember.position}
											error={
												!!(touched.teamMembers && touched.teamMembers[index]?.position) &&
												!!(errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).position
													? true
													: false)
											}
											helperText={
												touched.teamMembers &&
												touched.teamMembers[index]?.position &&
												errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).position
											}
											fullWidth
											sx={{ gridColumn: 'span 4' }}
											multiline
										/>
										<TextField
											label="Description"
											name={`teamMembers.${index}.description`}
											onChange={handleChange}
											onBlur={handleBlur}
											type="text"
											variant="filled"
											value={teamMember.description}
											error={
												!!(touched.teamMembers && touched.teamMembers[index]?.description) &&
												!!(errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).description
													? true
													: false)
											}
											helperText={
												touched.teamMembers &&
												touched.teamMembers[index]?.description &&
												errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).description
											}
											fullWidth
											sx={{ gridColumn: 'span 4' }}
											multiline
										/>
										<TextField
											label="LinkedIn Url"
											name={`teamMembers.${index}.linkedInUrl`}
											onChange={handleChange}
											onBlur={handleBlur}
											type="text"
											variant="filled"
											value={teamMember.linkedInUrl}
											error={
												!!(touched.teamMembers && touched.teamMembers[index]?.linkedInUrl) &&
												!!(errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).linkedInUrl
													? true
													: false)
											}
											helperText={
												touched.teamMembers &&
												touched.teamMembers[index]?.linkedInUrl &&
												errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).linkedInUrl
											}
											fullWidth
											sx={{ gridColumn: 'span 4' }}
											multiline
										/>
										<TextField
											label="Twitter Url"
											name={`teamMembers.${index}.twitterUrl`}
											onChange={handleChange}
											onBlur={handleBlur}
											type="text"
											variant="filled"
											value={teamMember.twitterUrl}
											error={
												!!(touched.teamMembers && touched.teamMembers[index]?.twitterUrl) &&
												!!(errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).twitterUrl
													? true
													: false)
											}
											helperText={
												touched.teamMembers &&
												touched.teamMembers[index]?.twitterUrl &&
												errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).twitterUrl
											}
											fullWidth
											sx={{ gridColumn: 'span 4' }}
											multiline
										/>
										<TextField
											label="Telegram Url"
											name={`teamMembers.${index}.telegramUrl`}
											onChange={handleChange}
											onBlur={handleBlur}
											type="text"
											variant="filled"
											value={teamMember.telegramUrl}
											error={
												!!(touched.teamMembers && touched.teamMembers[index]?.telegramUrl) &&
												!!(errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).telegramUrl
													? true
													: false)
											}
											helperText={
												touched.teamMembers &&
												touched.teamMembers[index]?.telegramUrl &&
												errors.teamMembers &&
												errors.teamMembers[index] &&
												(errors.teamMembers[index] as TeamMember).telegramUrl
											}
											fullWidth
											sx={{ gridColumn: 'span 4' }}
											multiline
										/>
										<Divider sx={{ gridColumn: 'span 4' }} />
										{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
									</Box>
								);
							})}
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color="secondary" variant="contained">
								Create New Featured Project
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
}
