import * as env from 'env-var';

import { AppConfiguration } from './AppConfiguration';

const baseUrl = env.get('REACT_APP_BASE_URL').required().asString();

export const Configuration: AppConfiguration = {
	version: 1,

	baseUrl: baseUrl,

	maticBlockchain: {
		label: 'Polygon',
		chainId: 137,
		stableCoins: [{ label: 'USDC', address: '0x6F4C286A0A5a738ee198Cc2605Cc3Ac9f21b6423' }],
	},

	ethereumBlockchain: {
		label: 'ETH',
		chainId: 1,
		stableCoins: [{ label: 'USDC', address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48' }],
	},

	binanceBlockchain: {
		label: 'BNB',
		chainId: 56,
		stableCoins: [{ label: 'USDC', address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d' }],
	},

	binanceTestnetBlockchain: {
		label: 'TBNB',
		chainId: 97,
		stableCoins: [{ label: 'USDC', address: '0x875e0fe234d8CCd52786a5F4098D84294d045aE5' }],
	},

	baseBlockchain: {
		label: 'Base',
		chainId: 8453,
		stableCoins: [{ label: 'USDC', address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913' }],
	},

	statusFundingRound: [
		'pending',
		'signUp',
		'signUpEnded',
		'fundingReady',
		'funding',
		'fundingEnded',
		'waiting',
		'distributing',
		'closed',
	],

	typeFeaturedProject: ['fundraiser', 'placeholder', 'showOff'],

	adminRoles: ['admin', 'superAdmin', 'owner'],

	blogCategory: ['update', 'post'],
};
