import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from '../../hooks/hooks';
import { logout } from '../../redux/features/configSlice';
import { router } from '../../Router';

import { ColorModeContext } from '../../theme';

const Topbar = () => {
	const theme = useTheme();
	const colorMode = useContext(ColorModeContext);

	const dispatch = useDispatch();
	const history = useHistory();

	const logoutClick = async () => {
		await dispatch(logout());
		history.push(router.login().$);
	};

	return (
		<Box display="flex" justifyContent="end" p={2}>
			{/* ICONS */}
			<Box display="flex">
				<IconButton onClick={colorMode.toggleColorMode}>
					{theme.palette.mode === 'dark' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
				</IconButton>
				<IconButton onClick={logoutClick}>
					<ExitToAppIcon sx={{ color: red[800] }} />
					<Typography>Logout</Typography>
				</IconButton>
			</Box>
		</Box>
	);
};

export default Topbar;
