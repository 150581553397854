// prettier-ignore
import ViewIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { SupportMessage } from '../redux/features/configSlice';
import { router } from '../Router';

interface tableListProps {
	tableList: SupportMessage[];
}

export function SupportMessagesTable({ tableList }: tableListProps) {
	const history = useHistory();

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	const ActionButtonsRenderer = (props: any) => (
		<strong>
			<IconButton
				aria-label="Edit"
				sx={{ color: 'green', ':hover': { bgcolor: '#dcdefc' } }}
				onClick={async (e) => {
					history.push(router.getSupportMessage({ id: props.value }).$);
				}}
			>
				<ViewIcon />
			</IconButton>
		</strong>
	);

	const columns = [
		{ headerName: 'Email', field: 'email', sortable: true, filter: true, floatingFilter: true },
		{ headerName: 'Name', field: 'name', sortable: true, filter: true, floatingFilter: true },
		{
			headerName: 'Is Viewed',
			field: 'isViewed',
			sortable: true,
			filter: true,
			floatingFilter: true,
			cellStyle: (params: any) => {
				return params.value === true ? { color: 'green' } : { color: 'red' };
			},
		},
		{
			headerName: 'View',
			cellStyle: { border: 'none' },
			field: 'id',
			cellRenderer: 'actionRenderer',
		},
	];

	return (
		<AgGridReact
			columnDefs={columns}
			rowData={tableList}
			rowSelection="single"
			pagination={true}
			paginationAutoPageSize={true}
			frameworkComponents={{
				actionRenderer: ActionButtonsRenderer,
			}}
		/>
	);
}
