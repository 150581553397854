import { Configuration } from '../config/Config';
import { FeaturedProject, FeaturedProjectData } from '../redux/features/featuredProjectSlice';
import { FeaturedProjectService } from './url';

export const featuredProjectFetches = {
	listFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${FeaturedProjectService.LIST_FEATURED_PROJECTS}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},

	addFetch: async (featuredProjectData: FeaturedProjectData) => {
		const response = await fetch(`${Configuration.baseUrl}${FeaturedProjectService.CREATE_FEATURED_PROJECT}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				...featuredProjectData,
			}),
		});
		return await response;
	},

	updatePhotoFetch: async (formData: FormData) => {
		const response = await fetch(`${Configuration.baseUrl}${FeaturedProjectService.UPDATE_FEATURED_PROJECT}`, {
			method: 'POST',
			headers: {
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: formData,
		});
		return await response;
	},

	updateFetch: async (featuredProjectData: FeaturedProjectData) => {
		const response = await fetch(`${Configuration.baseUrl}${FeaturedProjectService.UPDATE_FEATURED_PROJECT}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				...featuredProjectData,
			}),
		});
		return await response;
	},

	makePublicFetch: async (id: number) => {
		const response = await fetch(`${Configuration.baseUrl}${FeaturedProjectService.MAKE_PUBLIC_FEATURED_PROJECT}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				id,
			}),
		});
		return await response;
	},

	removeFetch: async (id: number) => {
		const response = await fetch(`${Configuration.baseUrl}${FeaturedProjectService.REMOVE_FEATURED_PROJECT}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				id,
			}),
		});
		return await response;
	},

	removePhotoFetch: async (featuredProject: FeaturedProject, urlType: string) => {
		const response = await fetch(
			`${Configuration.baseUrl}${FeaturedProjectService.REMOVE_PHOTO_FEATURED_PROJECT}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accepts: 'application/json',
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				body: JSON.stringify({
					...featuredProject,
					urlType,
				}),
			}
		);
		return await response;
	},

	getFetch: async (featuredProjectId?: string) => {
		let getUrl = `${Configuration.baseUrl}${FeaturedProjectService.GET_FEATURED_PROJECT}`;

		if (featuredProjectId !== undefined) {
			getUrl = `${getUrl}/${featuredProjectId}`;
		}

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},

	setCurrentFundingRoundFetch: async (featuredProjectId: number, fundingRoundId: number) => {
		const response = await fetch(`${Configuration.baseUrl}${FeaturedProjectService.SET_CURRENT_FUNDING_ROUND}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				featuredProjectId,
				fundingRoundId,
			}),
		});
		return await response;
	},
};
