import { Box, Button, FormControlLabel, Switch, TextField, useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from 'react-typesafe-routes';
import * as yup from 'yup';
import Header from '../../components/global/Header';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { logout } from '../../redux/features/configSlice';
import { Project, update } from '../../redux/features/projectSlice';
import { router } from '../../Router';
import { projectFetches } from '../../service';

export function GetProjectPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const { id } = useRouteParams(router.getProject);
	const selectedProject = useSelector((state: RootState) => state.projectReducer.selectedProject);
	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const [checkVisible, setCheckVisible] = React.useState({ checked: selectedProject?.isVisible });
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	const handleUpdateFormSubmit = async (values: Project) => {
		const projectToUpdate = {
			...values,
			id: id,
			isVisible: checkVisible.checked,
		} as Project;
		const updateResponse = await projectFetches.updateFetch(projectToUpdate);

		if (updateResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await updateResponse.json();

		if (data.status === true) {
			dispatch(update(projectToUpdate));
			history.push(router.listProjects().$);
			// history.push(router.getProject({ id: id }).$);
		} else {
			setServerErrorMessage(`${data.error}`);
		}
	};

	const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckVisible({ ...checkVisible, [event.target.name]: event.target.checked });
	};

	const projectValidationSchema = yup.object().shape({
		firstName: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		lastName: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		projectName: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		email: yup.string().email('invalid email').required('required'),
		website: yup.string().url('invalid url').required('required'),
		twitter: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		personalTelegramHandle: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		projectTelegramGroup: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		onePager: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		marketingDeck: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		tokenomics: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		evidenceOfDevelopment: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		collectiveSource: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		description: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		isVisible: yup.boolean().required('required'),
	});

	return selectedProject ? (
		<Box m="20px">
			<Box sx={{ gridColumn: 'span 4' }} display="flex" justifyContent="flex-start">
				<Header title="PROJECT INFO" subtitle="Project data / Update Project" />
				<FormControlLabel
					control={
						<Switch
							checked={checkVisible.checked}
							onChange={handleCheckChange}
							name="checked"
							color="success"
						/>
					}
					label="Visible"
				/>
			</Box>
			<Formik
				onSubmit={handleUpdateFormSubmit}
				initialValues={selectedProject!}
				validationSchema={projectValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="First Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.firstName}
								name="firstName"
								error={!!touched.firstName && !!errors.firstName}
								helperText={touched.firstName && errors.firstName}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Last Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.lastName}
								name="lastName"
								error={!!touched.lastName && !!errors.lastName}
								helperText={touched.lastName && errors.lastName}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Project Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.projectName}
								name="projectName"
								error={!!touched.projectName && !!errors.projectName}
								helperText={touched.projectName && errors.projectName}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Twitter"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.twitter}
								name="twitter"
								error={!!touched.twitter && !!errors.twitter}
								helperText={touched.twitter && errors.twitter}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Email"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email}
								name="email"
								error={!!touched.email && !!errors.email}
								helperText={touched.email && errors.email}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Website"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.website}
								name="website"
								error={!!touched.website && !!errors.website}
								helperText={touched.website && errors.website}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Personal Telegram Handle"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.personalTelegramHandle}
								name="personalTelegramHandle"
								error={!!touched.personalTelegramHandle && !!errors.personalTelegramHandle}
								helperText={touched.personalTelegramHandle && errors.personalTelegramHandle}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Project Telegram Group"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.projectTelegramGroup}
								name="projectTelegramGroup"
								error={!!touched.projectTelegramGroup && !!errors.projectTelegramGroup}
								helperText={touched.projectTelegramGroup && errors.projectTelegramGroup}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Onepager"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.onePager}
								name="onePager"
								error={!!touched.onePager && !!errors.onePager}
								helperText={touched.onePager && errors.onePager}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Marketing Deck"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.marketingDeck}
								name="marketingDeck"
								error={!!touched.marketingDeck && !!errors.marketingDeck}
								helperText={touched.marketingDeck && errors.marketingDeck}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Tokenomics"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.tokenomics}
								name="tokenomics"
								error={!!touched.tokenomics && !!errors.tokenomics}
								helperText={touched.tokenomics && errors.tokenomics}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Evidence of Development"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.evidenceOfDevelopment}
								name="evidenceOfDevelopment"
								error={!!touched.evidenceOfDevelopment && !!errors.evidenceOfDevelopment}
								helperText={touched.evidenceOfDevelopment && errors.evidenceOfDevelopment}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Collective Source"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.collectiveSource}
								name="collectiveSource"
								error={!!touched.collectiveSource && !!errors.collectiveSource}
								helperText={touched.collectiveSource && errors.collectiveSource}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Description"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.description}
								name="description"
								error={!!touched.description && !!errors.description}
								helperText={touched.description && errors.description}
								sx={{ gridColumn: 'span 4' }}
							/>
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color="secondary" variant="contained">
								Update Project
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	) : null;
}
