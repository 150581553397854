export enum FeaturedProjectService {
	LIST_FEATURED_PROJECTS = '/api/v1/featured-projects/list-all-featured-projects',
	MAKE_PUBLIC_FEATURED_PROJECT = '/api/v1/featured-projects/make-public-featured-project',
	CREATE_FEATURED_PROJECT = '/api/v1/featured-projects/create-featured-project',
	UPDATE_FEATURED_PROJECT = '/api/v1/featured-projects/update-featured-project',
	REMOVE_FEATURED_PROJECT = '/api/v1/featured-projects/remove-featured-project',
	GET_FEATURED_PROJECT = '/api/v1/featured-projects/get-featured-project',
	REMOVE_PHOTO_FEATURED_PROJECT = '/api/v1/featured-projects/remove-photo-featured-project',
	SET_CURRENT_FUNDING_ROUND = '/api/v1/featured-projects/set-current-funding-round',
}
