// prettier-ignore
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import loaderImg from '../loader.gif';

interface SimpleDialogProps {
	open: boolean;
	// 	onClose: () => void;
}

export function Loader(props: SimpleDialogProps) {
	const { open } = props;

	return (
		<Dialog open={open}>
			<Box bgcolor="white">
				<img src={loaderImg}></img>
			</Box>
		</Dialog>
	);
}
