// prettier-ignore
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { RouterSwitch } from 'react-typesafe-routes';
import Sidebar from './components/global/Sidebar';
import Topbar from './components/global/Topbar';
import { RootState } from './redux/configureStore';
import { router } from './Router';
import { ColorModeContext, useMode } from './theme';

function App() {
	const loggedIn = useSelector((state: RootState) => state.configReducer.loggedIn);
	const [theme, colorMode] = useMode();

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<ColorModeContext.Provider value={colorMode as any}>
				<ThemeProvider theme={theme as any}>
					<CssBaseline />
					{loggedIn === true ? (
						<div className="app">
							<Sidebar />
							<main className="content">
								<Topbar />
								<RouterSwitch router={router} />
							</main>
						</div>
					) : (
						<RouterSwitch router={router} />
					)}
				</ThemeProvider>
			</ColorModeContext.Provider>
		</LocalizationProvider>
	);
}

export default App;
