import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { supportedTokenFetches } from '../../service';
import { AppDispatch } from '../configureStore';
import { Chain } from './chainSlice';
import { logout } from './configSlice';

export interface SupportedToken {
	id?: number;
	name: string;
	chainId: number | null;
	address: string;
	symbol: string;
	logoUrl?: string;
	chain?: Chain;
}

export interface SupportedTokenState {
	supportedTokens: SupportedToken[];
	selectedSupportedToken: SupportedToken | null;
}

const supportedTokenInitialState: SupportedTokenState = {
	supportedTokens: [],
	selectedSupportedToken: null,
};

export const supportedTokenSlice = createSlice({
	name: 'supportedToken',
	initialState: supportedTokenInitialState,
	reducers: {
		add: (state, action: PayloadAction<SupportedToken>) => {
			state.supportedTokens.push(action.payload);
		},
		get: (state, action: PayloadAction<SupportedToken[]>) => {
			state.supportedTokens = action.payload;
		},
		update: (state, action: PayloadAction<SupportedToken>) => {
			for (const index in state.supportedTokens) {
				if (state.supportedTokens[index].id === action.payload.id && state.selectedSupportedToken) {
					state.supportedTokens[index] = action.payload;
					state.selectedSupportedToken = action.payload;
				}
			}
		},
		deleteSupportedToken: (state, action: PayloadAction<number>) => {
			state.supportedTokens = state.supportedTokens.filter((t) => t.id !== action.payload);
		},
		selectSupportedToken: (state, action: PayloadAction<SupportedToken>) => {
			state.selectedSupportedToken = action.payload;
		},
		updateLogoPhoto: (state, action: PayloadAction<SupportedToken>) => {
			for (const index in state.supportedTokens) {
				if (state.supportedTokens[index].id === action.payload.id && state.selectedSupportedToken) {
					state.supportedTokens[index].logoUrl = action.payload.logoUrl;
					state.selectedSupportedToken.logoUrl = action.payload.logoUrl;
				}
			}
		},
	},
});

export const initSupportedTokens = () => async (dispatch: AppDispatch) => {
	const listReponse = await supportedTokenFetches.listFetch();

	if (listReponse.status === 403) {
		await dispatch(logout());
		return;
	}
	const data = await listReponse.json();

	if (data.status === true) {
		await dispatch(get(data.data));
	}
};

// Action creators are generated for each case reducer function
export const { add, get, update, deleteSupportedToken, selectSupportedToken, updateLogoPhoto } =
	supportedTokenSlice.actions;

export default supportedTokenSlice.reducer;
