import { Autocomplete, Box, Button, TextField, useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from 'react-typesafe-routes';
import * as yup from 'yup';
import { router } from '../../Router';
import Header from '../../components/global/Header';
import { Configuration } from '../../config/Config';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { AdminUser, update } from '../../redux/features/adminUserSlice';
import { logout } from '../../redux/features/configSlice';
import { adminUserFetches } from '../../service';

export function GetAdminUserPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const dispatch = useDispatch();

	const { id } = useRouteParams(router.getAdminUser);
	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const currentAdminUser = useSelector((state: RootState) => state.currentAdminUserReducer);
	const selectedAdminUser = useSelector((state: RootState) => state.adminUserReducer.selectedAdminUser);

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	const handleUpdateFormSubmit = async (values: AdminUser) => {
		const adminUserToUpdate = {
			...values,
			id: id,
		} as AdminUser;

		const updateResponse = await adminUserFetches.updateFetch(adminUserToUpdate);

		if (updateResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await updateResponse.json();

		if (data.status === true) {
			const adminUserToAddToStore = data.data;

			dispatch(update(adminUserToAddToStore));
			history.push(router.listAdminUsers().$);
			// history.push(router.getAdminUser({ id: id }).$);
		} else {
			setServerErrorMessage(`${data.error}`);
		}
	};

	const adminUserValidationSchema = yup.object().shape({
		email: yup.string().email('invalid email').required('required'),
		adminUserRole: yup.string().required('requied'),
		password: yup.string().min(4, 'must be at least 4 characters long').optional(),
	});

	return selectedAdminUser ? (
		<Box m="20px">
			<Header title="ADMIN USER INFO" subtitle="Admin User data / Update Admin User" />
			<Formik
				onSubmit={handleUpdateFormSubmit}
				initialValues={selectedAdminUser}
				validationSchema={adminUserValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Email"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email}
								name="email"
								error={!!touched.email && !!errors.email}
								helperText={touched.email && errors.email}
								sx={{ gridColumn: 'span 4' }}
								inputProps={{ readOnly: true }}
							/>
							{currentAdminUser.role === 'owner' && (
								<TextField
									fullWidth
									variant="filled"
									type="password"
									label="New Password"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.password}
									name="password"
									error={!!touched.password && !!errors.password}
									helperText={touched.password && errors.password}
									sx={{ gridColumn: 'span 4' }}
								/>
							)}
							<Autocomplete
								options={Configuration.adminRoles}
								disableClearable
								selectOnFocus
								clearOnBlur
								onChange={(e, value) => setFieldValue('adminUserRole', value)}
								autoHighlight
								sx={{ gridColumn: 'span 2' }}
								getOptionLabel={(option) => option}
								value={values.adminUserRole}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant="filled"
										type="text"
										onBlur={handleBlur}
										label="Admin Role"
										name="adminUserRole"
										error={!!touched.adminUserRole && !!errors.adminUserRole}
										helperText={touched.adminUserRole && errors.adminUserRole}
									/>
								)}
							/>
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color="secondary" variant="contained">
								Update Admin User
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	) : null;
}
