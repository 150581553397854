// prettier-ignore
import ContractIcon from '@mui/icons-material/ArticleOutlined';
import CheckIcon from '@mui/icons-material/Check';
import UncheckIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Divider, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { router } from '../Router';
import { useDispatch, useSelector } from '../hooks/hooks';
import { RootState } from '../redux/configureStore';
import { logout } from '../redux/features/configSlice';
import {
	addContractsData,
	setFundingContractAddress,
	setVestingContractAddress,
} from '../redux/features/fundingRoundSlice';
import { fundingRoundFetches } from '../service';
import { tokens } from '../theme';
import ConfirmationDialog from './ConfirmationDialog';
import { Loader } from './Loader';
import Header from './global/Header';

export function FundingRoundContract() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const CONTRACT_VERSIONS = { funding: 'funding', vesting: 'vesting' };

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const [action, setAction] = React.useState(() => () => {}); // Default action, does nothing
	const [actionName, setActionName] = React.useState('');

	// get selected fetured project from redux store
	const selectedFeaturedProject = useSelector(
		(state: RootState) => state.featuredProjectReducer.selectedFeaturedProject
	);
	const selectedFundingRound = useSelector((state: RootState) => state.fundingRoundReducer.selectedFundingRound);

	const dispatch = useDispatch();

	const handleRefresh = async () => {
		// refetch data from contract
		if (selectedFundingRound?.id && selectedFundingRound.fundingContractAddress) {
			fetchContractData();
		}
		if (selectedFundingRound?.id && selectedFundingRound.vestingContractAddress) {
			fetchContractData();
		}
	};

	useEffect(() => {
		// verify if logged in
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
		if (
			selectedFundingRound &&
			!selectedFundingRound.fundingContractData &&
			selectedFundingRound.fundingContractAddress
		) {
			setServerErrorMessage('');
			fetchContractData();
		}
		if (
			selectedFundingRound &&
			!selectedFundingRound.vestingContractData &&
			selectedFundingRound.vestingContractAddress
		) {
			setServerErrorMessage('');
			fetchContractData();
		}
	}, [history, selectedFundingRound?.fundingContractAddress, selectedFundingRound?.vestingContractAddress]);

	const createContract = async (version: string) => {
		setIsLoading(true);
		// request contract creation in solidity-backend / Release version + Marketplace version
		let createContractResponse;
		if (version === CONTRACT_VERSIONS.funding) {
			createContractResponse = await fundingRoundFetches.createCustodyLedgerContract(selectedFundingRound?.id!);
		} else {
			createContractResponse = await fundingRoundFetches.createVestingTermsContract(selectedFundingRound?.id!);
		}
		if (createContractResponse.status === 403) {
			dispatch(logout());
			return;
		}
		const data = await createContractResponse.json();

		if (data.status === true) {
			const dataToStore = {
				fundingRoundId: selectedFundingRound?.id,
				contractAddress: data.data.contractAddress,
			};
			if (version === CONTRACT_VERSIONS.funding) {
				dispatch(setFundingContractAddress(dataToStore));
			} else {
				dispatch(setVestingContractAddress(dataToStore));
			}

			if (selectedFundingRound?.fundingContractAddress) {
				fetchContractData();
			}
			if (selectedFundingRound?.vestingContractAddress) {
				fetchContractData();
			}

			setIsLoading(false);
			if (isLoading === false) {
				// go to selected featured project page
				history.push(
					router.getFundingRound({
						featuredProjectId: selectedFeaturedProject?.id,
						fundingRoundId: selectedFundingRound?.id,
					}).$
				);
			}
		} else {
			setServerErrorMessage(data.error.value);
			setIsLoading(false);
		}
	};

	const fetchContractData = async () => {
		setIsLoading(true);
		const contractsDataResponse = await fundingRoundFetches.getContractsDataFundingRound(selectedFundingRound?.id!);

		if (contractsDataResponse.status === 403) {
			dispatch(logout());
			return;
		}
		const data = await contractsDataResponse.json();

		if (data.status === true) {
			dispatch(addContractsData({ id: selectedFundingRound?.id!, contractData: data.data }));
			setIsLoading(false);
		} else {
			setServerErrorMessage(data.error.value);
			setIsLoading(false);
		}
	};

	const handleRedeemUnsoldTokens = async () => {
		setIsLoading(true);
		const redeemResponse = await fundingRoundFetches.redeemUnsoldTokensFromVestingContract(
			selectedFundingRound?.id!
		);

		if (redeemResponse.status === 403) {
			dispatch(logout());
			return;
		}
		const data = await redeemResponse.json();

		if (data.status === true) {
			handleRefresh();
			setIsLoading(false);
		} else {
			setServerErrorMessage(data.error.value);
			setIsLoading(false);
		}
	};

	const handleWithdrawRaisedStableCoins = async () => {
		setIsLoading(true);
		const withdrawResponse = await fundingRoundFetches.withdrawRaisedStableCoinsFromFundingContract(
			selectedFundingRound?.id!
		);

		if (withdrawResponse.status === 403) {
			dispatch(logout());
			return;
		}
		const data = await withdrawResponse.json();

		if (data.status === true) {
			handleRefresh();
			setIsLoading(false);
		} else {
			setServerErrorMessage(data.error.value);
			setIsLoading(false);
		}
	};

	const handleConfirmation = (confirmedAction: any, actionName: string) => {
		setAction(() => confirmedAction);
		setActionName(actionName);
		// Open the dialog
		setIsOpen(true);
	};

	const handleConfirm = () => {
		// Implement the logic to make the project public
		action(); // Perform the action associated with the button
		// Close the dialog
		setIsOpen(false);
	};

	const handleCancel = () => {
		// Close the dialog without making the project public
		setIsOpen(false);
	};

	return (
		<Box>
			{isLoading ? <Loader open={isLoading} /> : null}
			<Box display="flex" justifyContent="flex-start" gap="2px">
				<Header title="FUNDING ROUND CONTRACTS" subtitle={` Funding Round - ${selectedFundingRound!.name}`} />
				<Button
					variant="contained"
					startIcon={<RefreshIcon />}
					color="secondary"
					onClick={handleRefresh}
					size="large"
					sx={{ alignSelf: 'flex-start' }}
				>
					Refresh
				</Button>
				<Button
					variant="contained"
					color="secondary"
					startIcon={<ContractIcon />}
					onClick={() =>
						handleConfirmation(
							() => createContract(CONTRACT_VERSIONS.funding),
							'Are you sure you want to create custody ledger contract?'
						)
					}
					size="large"
					sx={{ alignSelf: 'flex-start' }}
					disabled={!selectedFundingRound?.fundingContractAddress ? false : true}
				>
					Create Custody Ledger Contract
				</Button>
				<Button
					variant="contained"
					color="secondary"
					startIcon={<ContractIcon />}
					onClick={() =>
						handleConfirmation(
							() => createContract(CONTRACT_VERSIONS.vesting),
							'Are you sure you want to create vesting terms contract?'
						)
					}
					size="large"
					sx={{ alignSelf: 'flex-start' }}
					disabled={!selectedFundingRound?.vestingContractAddress ? false : true}
				>
					Create Vesting Terms Contract
				</Button>
				<ConfirmationDialog
					open={isOpen}
					onClose={handleCancel}
					onConfirm={handleConfirm}
					content={actionName}
				/>
				<Typography
					variant="h6"
					sx={{ color: colors.redAccent[500], alignSelf: 'flex-start', width: '29%', ml: '10px', mt: '7px' }}
				>
					{serverErrorMessage && <span> -{serverErrorMessage}- </span>}
				</Typography>
			</Box>
			<Divider sx={{ gridColumn: 'span 4', mt: '30px' }} />
			<Box mt="40px" mb="40px" gap="30%">
				<Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
					Criteria for Vesting Terms Contract Creation
				</Typography>
				<Box mt="20px" display="flex" flexDirection="column">
					<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
						Funding Round Vesting Period:
						{selectedFundingRound?.vestingPeriod ? (
							<CheckIcon sx={{ color: 'green' }} fontSize="large" />
						) : (
							<UncheckIcon sx={{ color: 'red' }} fontSize="large" />
						)}
					</Typography>
					<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
						Funding Round Start Redeem Percentage:
						{selectedFundingRound?.startRedeemPercentage ? (
							<CheckIcon sx={{ color: 'green' }} fontSize="large" />
						) : (
							<UncheckIcon sx={{ color: 'red' }} fontSize="large" />
						)}
					</Typography>
				</Box>
			</Box>
			<Divider sx={{ gridColumn: 'span 4' }} />
			<Box display="flex">
				{selectedFundingRound?.fundingContractData && (
					// we dont use onSubmit, but is needed for Formik to work
					<Box flex={1} p={2}>
						<Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
							Custody Ledger Contract Data
						</Typography>
						<Formik onSubmit={() => {}} initialValues={selectedFundingRound?.fundingContractData!}>
							{({ values, handleBlur }) => (
								<form>
									<Box
										mt="40px"
										display="grid"
										gap="30px"
										gridTemplateColumns="repeat(4,minmax(0,1fr))"
										sx={{
											'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
										}}
									>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Funding Contract Address"
											onBlur={handleBlur}
											value={selectedFundingRound.fundingContractAddress}
											name="fundingContractAddress"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Tokens For Sale Amount"
											onBlur={handleBlur}
											value={values.tokensForSaleAmount}
											name="tokensForSaleAmount"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Stable Coin Amount"
											onBlur={handleBlur}
											value={values.stableCoinAmount}
											name="stableCoinAmount"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="User Max Limit"
											onBlur={handleBlur}
											value={values.userMaxLimit}
											name="userMaxLimit"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="User Min Limit"
											onBlur={handleBlur}
											value={values.userMinLimit}
											name="userMinLimit"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Remaining Tokens For Sale Amount"
											onBlur={handleBlur}
											value={values.remainingTokenForSaleAmount}
											name="remainingTokenForSaleAmount"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Stable Coin Amount Raised"
											onBlur={handleBlur}
											value={values.stableCoinAmountRaised}
											name="stableCoinAmountRaised"
											sx={{ gridColumn: 'span 2' }}
											inputProps={{ readOnly: true }}
										/>
										<Button
											variant="contained"
											color="secondary"
											onClick={() =>
												handleConfirmation(
													handleWithdrawRaisedStableCoins,
													'Are you sure you want to Withdraw Raised Stable Coins from contract?'
												)
											}
											size="large"
											sx={{ gridColumn: 'span 2' }}
											disabled={
												selectedFundingRound?.fundingContractData?.stableCoinAmountRaised !== 0
													? false
													: true
											}
										>
											Withdraw Stable Coins
										</Button>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Tokens Per Stable Coind"
											onBlur={handleBlur}
											value={values.tokenPerStableCoin}
											name="tokenPerStableCoin"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
									</Box>
								</form>
							)}
						</Formik>
					</Box>
				)}
				{selectedFundingRound?.vestingContractData && <Divider orientation="vertical" flexItem />}

				{selectedFundingRound?.vestingContractData && (
					// we dont use onSubmit, but is needed for Formik to work
					<Box flex={1} p={2}>
						<Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
							Vesting Terms Contract Data
						</Typography>
						<Formik onSubmit={() => {}} initialValues={selectedFundingRound?.vestingContractData!}>
							{({ values, handleBlur }) => (
								<form>
									<Box
										mt="40px"
										display="grid"
										gap="30px"
										gridTemplateColumns="repeat(4,minmax(0,1fr))"
										sx={{
											'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
										}}
									>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Vesting Contract Address"
											onBlur={handleBlur}
											value={selectedFundingRound.vestingContractAddress}
											name="vestingContractAddress"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Distributing Started"
											onBlur={handleBlur}
											value={values.distributingStarted}
											name="distributingStarted"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Vesting Period"
											onBlur={handleBlur}
											value={values.vestingPeriod}
											name="vestingPeriod"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Start Vesting Percentage"
											onBlur={handleBlur}
											value={values.startVestingPercentage}
											name="startVestingPercentage"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Vesting Start Time"
											onBlur={handleBlur}
											value={values.vestingStartTime}
											name="vestingStartTime"
											sx={{ gridColumn: 'span 4' }}
											inputProps={{ readOnly: true }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Unsold Token Amount"
											onBlur={handleBlur}
											value={values.unsoldTokenAmount}
											name="unsoldTokenAmount"
											sx={{ gridColumn: 'span 2' }}
											inputProps={{ readOnly: true }}
										/>
										<Button
											variant="contained"
											color="secondary"
											onClick={() =>
												handleConfirmation(
													handleRedeemUnsoldTokens,
													'Are you sure you want to Redeem Unsold Tokens from contract?'
												)
											}
											size="large"
											sx={{ gridColumn: 'span 2' }}
											disabled={
												selectedFundingRound?.vestingContractData?.unsoldTokenAmount !== 0
													? false
													: true
											}
										>
											Redeem Unsold Tokens
										</Button>
									</Box>
								</form>
							)}
						</Formik>
					</Box>
				)}
			</Box>
		</Box>
	);
}
