import TreasuryOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CompanyWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import MessagesOutlinedIcon from '@mui/icons-material/ForumOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ProjectsOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import AdminsOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import BlogsOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import MarketplaceOutlinedIcon from '@mui/icons-material/StoreOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import FeauredProjectsOutlinedIcon from '@mui/icons-material/WebOutlined';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Menu, MenuItem, ProSidebar } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link } from 'react-router-dom';
import { useSelector } from '../../hooks/hooks';
import Logo from '../../logo';
import { RootState } from '../../redux/configureStore';
import { tokens } from '../../theme';

const Item = ({ title, to, icon, selected, setSelected }: any) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<MenuItem
			active={selected === title}
			style={{
				color: colors.grey[100],
			}}
			onClick={() => {
				setSelected(title);
				localStorage.setItem('activeTab', title);
			}}
			icon={icon}
		>
			<Typography>{title}</Typography>
			<Link to={to} />
		</MenuItem>
	);
};

const Sidebar = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [selected, setSelected] = useState(
		localStorage.getItem('activeTab') && window.location.pathname !== '/'
			? localStorage.getItem('activeTab')
			: 'Dashboard'
	);
	const currentAdminUser = useSelector((state: RootState) => state.currentAdminUserReducer);

	return (
		<Box
			sx={{
				'& .pro-sidebar-inner': {
					background: `${colors.primary[400]} !important`,
				},
				'& .pro-icon-wrapper': {
					backgroundColor: 'transparent !important',
				},
				'& .pro-inner-item': {
					padding: '5px 35px 5px 20px !important',
				},
				'& .pro-inner-item:hover': {
					color: '#868dfb !important',
				},
				'& .pro-menu-item.active': {
					color: '#6870fa !important',
				},
			}}
		>
			<ProSidebar collapsed={isCollapsed}>
				<Menu iconShape="square">
					{/* LOGO AND MENU ICON */}
					<MenuItem
						onClick={() => setIsCollapsed(!isCollapsed)}
						icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
						style={{
							margin: '10px 0 20px 0',
							color: colors.grey[100],
						}}
					>
						{!isCollapsed && (
							<Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
								<Typography variant="h3" color={colors.grey[100]}>
									ADMIN
								</Typography>
								<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
									<MenuOutlinedIcon />
								</IconButton>
							</Box>
						)}
					</MenuItem>

					{!isCollapsed && (
						<Box mb="25px">
							<Box textAlign="center">
								<Logo />
								{/* <img
									src={process.env.PUBLIC_URL + '/logoCollective.webp'}
									width="190"
									height="75"
									alt=""
								/> */}
								<Typography
									variant="h4"
									color={colors.grey[100]}
									fontWeight="bold"
									sx={{ m: '10px 0 0 0' }}
								>
									{currentAdminUser.email !== undefined ? currentAdminUser.email : null}
								</Typography>
								<Typography variant="h5" color={colors.greenAccent[500]}>
									{currentAdminUser.email !== undefined ? currentAdminUser.role : null}
								</Typography>
							</Box>
						</Box>
					)}

					<Box paddingLeft={isCollapsed ? undefined : '10%'}>
						<Item
							title="Dashboard"
							to="/"
							icon={<HomeOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>

						<Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
							Data
						</Typography>
						<Item
							title="Featured Projects"
							to="/list-featured-projects"
							icon={<FeauredProjectsOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Projects"
							to="/list-projects"
							icon={<ProjectsOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Supported Tokens"
							to="/list-supported-tokens"
							icon={<TokenOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Blog Posts"
							to="/list-blog-posts"
							icon={<BlogsOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Support Messages"
							to="/list-support-messages"
							icon={<MessagesOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						{currentAdminUser.role === 'owner' ? (
							<div>
								<Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
									Owner data
								</Typography>
								<Item
									title="Admin Users"
									to="/list-admin-users"
									icon={<AdminsOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
								<Item
									title="Treasury Wallet"
									to="/treasury-wallet"
									icon={<TreasuryOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
								<Item
									title="Company Wallets"
									to="/company-wallets"
									icon={<CompanyWalletOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
								<Item
									title="Marketplace"
									to="/marketplace"
									icon={<MarketplaceOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							</div>
						) : null}
					</Box>
				</Menu>
			</ProSidebar>
		</Box>
	);
};

export default Sidebar;
