// prettier-ignore
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { router } from '../Router';
import { useDispatch, useSelector } from '../hooks/hooks';
import { RootState } from '../redux/configureStore';
import { deleteBlogPost, selectBlogPost } from '../redux/features/blogPostSlice';
import { logout } from '../redux/features/configSlice';
import { blogPostFetches } from '../service';

export function BlogPostsTable() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');

	const blogPostsList = useSelector((state: RootState) => state.blogPostReducer.blogPosts);

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	// set, edit and delete button creation, used in table to view
	const ActionButtonsRenderer = (props: any) => (
		<strong>
			<IconButton
				aria-label="Edit"
				sx={{ color: 'green', ':hover': { bgcolor: '#dcdefc' } }}
				onClick={async (e) => {
					// set selected supported token on edit button click from table
					dispatch(selectBlogPost(props.data));
					// go to selected supported token info page
					history.push(router.getBlogPost({ id: props.value }).$);
				}}
			>
				<UpdateIcon />
			</IconButton>
			<IconButton
				aria-label="Delete"
				sx={{ color: 'red', ':hover': { bgcolor: '#dcdefc' } }}
				onClick={async (e) => {
					if (props.value) {
						// execute delete request if id exists
						const removeResponse = await blogPostFetches.removeFetch(
							props.value as number,
							props.data.photoUrl
						);

						if (removeResponse.status === 403) {
							await dispatch(logout());
							return;
						}
						const data = await removeResponse.json();

						if (data.status === true) {
							// delete data from redux store
							await dispatch(deleteBlogPost(props.value as number));
						} else {
							setServerErrorMessage(`${data.error}`);
						}
					}
				}}
			>
				<DeleteIcon />
			</IconButton>
		</strong>
	);

	const columns = [
		{ headerName: 'Title', field: 'title', sortable: true, filter: true, floatingFilter: true },
		{ headerName: 'Category', field: 'category', sortable: true, filter: true, floatingFilter: true },
		{ headerName: 'Blog Link', field: 'blogLink', sortable: true, filter: true, floatingFilter: true },
		{
			headerName: 'Is Featured',
			field: 'isFeatured',
			sortable: true,
			filter: true,
			floatingFilter: true,
			cellStyle: (params: any) => {
				return params.value === true ? { color: 'green' } : { color: 'red' };
			},
		},

		{
			headerName: 'Edit / Delete',
			cellStyle: { border: 'none' },
			field: 'id',
			cellRenderer: 'actionRenderer',
		},
	];

	return (
		<AgGridReact
			columnDefs={columns}
			rowData={blogPostsList}
			rowSelection="single"
			pagination={true}
			paginationAutoPageSize={true}
			frameworkComponents={{
				actionRenderer: ActionButtonsRenderer,
			}}
		/>
	);
}
