import PriorityIcon from '@mui/icons-material/PriorityHighSharp';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';

interface Props {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	content: string;
}

const ConfirmationDialog = ({ open, onClose, onConfirm, content }: Props) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<Divider style={{ width: '100%', height: '20px', backgroundColor: '#D2042D' }} />
			<DialogTitle
				style={{
					backgroundColor: '#fff',
					color: '#000000',
					textAlign: 'center',
					display: 'flex',
					alignItems: 'center',
					fontSize: '20px',
				}}
			>
				<PriorityIcon style={{ color: '#D2042D', fontSize: '50px' }} />
				Confirmation
			</DialogTitle>
			<Divider style={{ width: '100%', backgroundColor: '#D2042D' }} />
			<DialogContent style={{ backgroundColor: '#fff', display: 'flex' }}>
				<DialogContentText style={{ fontSize: '16px', marginBottom: '16px' }}>
					{' '}
					<span style={{ color: '#000000', fontSize: '18px', fontWeight: 'bold' }}>{content}</span>
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{ backgroundColor: '#fff' }}>
				<Button onClick={onClose} variant="contained" style={{ color: '#000000', backgroundColor: '#fff' }}>
					No
				</Button>
				<Button
					onClick={onConfirm}
					variant="contained"
					sx={{
						color: '#fff',
						backgroundColor: '#D2042D',
						':hover': {
							backgroundColor: '#FF0000', // Change the color on hover
						},
					}}
					autoFocus
				>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationDialog;
