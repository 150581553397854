import { Autocomplete, Box, Button, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ethers } from 'ethers';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import Header from '../../components/global/Header';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { initChains } from '../../redux/features/chainSlice';
import { logout } from '../../redux/features/configSlice';
import { add, SupportedToken } from '../../redux/features/supportedTokenSlice';
import { router } from '../../Router';
import { supportedTokenFetches } from '../../service';
import { tokens } from '../../theme';

export function AddSupportedTokenPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const dispatch = useDispatch();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	// get chain list from redux store
	const chainsList = useSelector((state: RootState) => state.chainReducer.chains);
	const [selectedFile, setSelectedFile] = React.useState<{ logoFile: File; error: string }>();
	const supportedTokenInitialValues: SupportedToken = {
		name: '',
		chainId: null,
		address: '',
		symbol: '',
	};

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			// initialize chain list
			if (chainsList === null || chainsList === undefined || chainsList.length === 0) {
				dispatch(initChains());
			}
		}
	}, [history]);

	const handleAddFormSubmit = async (values: SupportedToken) => {
		if (selectedFile?.logoFile) {
			const formData = new FormData();
			formData.append('file', selectedFile!.logoFile as File);
			formData.append('supportedToken', JSON.stringify(values));

			// execute add request and add supported token to redux store
			const addResponse = await supportedTokenFetches.addFetch(formData);

			if (addResponse.status === 403) {
				await dispatch(logout());
				return;
			}
			const data = await addResponse.json();

			if (data.status === true) {
				// get created supported token from response
				const supportedTokenToStore = {
					...data.data,
					logoUrl: data.data.logoUrl,
				} as SupportedToken;

				// get chain data from redux store chain list using chain id
				const chainDataToStore = chainsList.filter((chain) => chain.id === data.data.chainId);
				supportedTokenToStore.chain = chainDataToStore[0];

				// set created supported token and chain data to redux store
				dispatch(add(supportedTokenToStore));

				history.push(router.listSupportedTokens().$);
			} else {
				setServerErrorMessage(data.error.value);
			}
		} else {
			setSelectedFile({ ...selectedFile!, error: 'Logo is not set!' });
		}
	};

	const supportedTokenValidationSchema = yup.object().shape({
		name: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		chainId: yup.string().required('required').nullable(),
		address: yup
			.string()
			.required('required')
			.test({
				name: 'addressError',
				message: 'invalid address',
				test: (value) => ethers.utils.isAddress(value!),
			}),
		symbol: yup.string().min(4, 'must be at least 4 characters long').required('required'),
	});

	return (
		<Box m="20px">
			<Header title="ADD SUPPORTED TOKEN" subtitle="Create a New Supported Token" />
			<Formik
				onSubmit={handleAddFormSubmit}
				initialValues={supportedTokenInitialValues}
				validationSchema={supportedTokenValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.name}
								name="name"
								error={!!touched.name && !!errors.name}
								helperText={touched.name && errors.name}
								sx={{ gridColumn: 'span 2' }}
							/>
							<Autocomplete
								options={chainsList}
								disableClearable
								selectOnFocus
								clearOnBlur
								onChange={(e, value) => setFieldValue('chainId', value.id)}
								autoHighlight
								sx={{ gridColumn: 'span 2' }}
								getOptionLabel={(option) => option.name}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant="filled"
										type="text"
										label="Chain"
										onBlur={handleBlur}
										name="chainId"
										error={!!touched.chainId && !!errors.chainId}
										helperText={touched.chainId && errors.chainId}
									/>
								)}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Address"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.address}
								name="address"
								error={!!touched.address && !!errors.address}
								helperText={touched.address && errors.address}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Symbol"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.symbol}
								name="symbol"
								error={!!touched.symbol && !!errors.symbol}
								helperText={touched.symbol && errors.symbol}
								sx={{ gridColumn: 'span 4' }}
							/>
							<Box mb="12px" display="flex" gap="10px" sx={{ gridColumn: 'span 4' }}>
								<Typography variant="h5" color={colors.primary[100]}>
									Set Supported Token Logo
								</Typography>
								<input
									type="file"
									style={{ color: colors.redAccent[500] }}
									onChange={(e) =>
										setSelectedFile({ ...selectedFile!, logoFile: e.target.files![0] })
									}
								/>
								<Typography
									variant="h6"
									sx={{ color: colors.redAccent[500], alignSelf: 'center', width: '45%' }}
								>
									{selectedFile?.error ? `-${selectedFile?.error}-` : null}
								</Typography>
							</Box>
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color="secondary" variant="contained">
								Create New Supported Token
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
}
