import { Configuration } from '../config/Config';
import { MarketplaceService } from './url';

export const marketplaceFetches = {
	getMarketplaceDataFetch: async (blockchainLabel: string) => {
		const response = await fetch(`${Configuration.baseUrl}${MarketplaceService.GET_MARKETPLACE_DATA}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				blockchainLabel,
			}),
		});
		return await response;
	},
	updateMarketplaceDataFetch: async (blockchainLabel: string, marketplaceData: any) => {
		const response = await fetch(`${Configuration.baseUrl}${MarketplaceService.UPDATE_MARKETPLACE_DATA}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				blockchainLabel,
				marketplaceData,
			}),
		});
		return await response;
	},
};
