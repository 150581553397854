import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fundingRoundFetches } from '../../service';
import { AppDispatch } from '../configureStore';
import { Chain } from './chainSlice';
import { logout } from './configSlice';

export interface FundingRoundData {
	fundingRound: FundingRound;
	overview: OverviewFundingRound;
}

export interface FundingContractData {
	tokensForSaleAmount: number;
	stableCoinAmount: number;
	userMaxLimit: number;
	userMinLimit: number;
	remainingTokenForSaleAmount: number;
	stableCoinAmountRaised: number;
	tokenPerStableCoin: number;
}

export interface VestingContractData {
	distributingStarted: boolean;
	vestingPeriod: number;
	startVestingPercentage: number;
	vestingStartTime: Date;
	unsoldTokenAmount: number;
}

export interface OverviewFundingRound {
	id?: number;
	highlights: string;
	product: string;
	problem: string;
	solution: string;
	businessModel: string;
	market: string;
	investors: string;
}

export interface FundingRound {
	id?: number;
	featuredProjectId: number;
	fundingContractAddress?: string;
	vestingContractAddress?: string;
	fundingChainId: number | null;
	vestingChainId: number | null;
	overviewId?: number;
	name: string;
	targetAmount: string;
	fundingChain?: Chain;
	vestingChain?: Chain;
	overview: OverviewFundingRound;
	dollarTargetAmount: string;
	status?: string;
	maxAllocation: string;
	minAllocation: string;
	fundingDeadline?: string | undefined | null;
	fundingStartDate?: string | undefined | null;
	signUpDeadline?: string | undefined | null;
	tokenForSaleAddress: string;
	stableCoinAddress: string;
	fundingContractData?: FundingContractData;
	vestingContractData?: VestingContractData;
	initialMarketCap: string;
	initialTokenSupply: string;
	pricePerToken: string;
	vestingDuration?: string;
	vestingStartDate?: string | undefined | null;
	signUpStartDate?: string | undefined | null;
	vestingPeriod?: number | null;
	startRedeemPercentage?: number | null;
	onePagerUrl?: string;
	whitePaperUrl?: string;
	motto?: string;
}

export interface FundingRoundState {
	fundingRounds: FundingRound[];
	selectedFundingRound: FundingRound | null;
}

const fundingRoundInitialState: FundingRoundState = {
	fundingRounds: [],
	selectedFundingRound: null,
};

export const fundingRoundSlice = createSlice({
	name: 'fundingRound',
	initialState: fundingRoundInitialState,
	reducers: {
		add: (state, action: PayloadAction<FundingRound>) => {
			state.fundingRounds.push(action.payload);
		},
		get: (state, action: PayloadAction<FundingRound[]>) => {
			state.fundingRounds = action.payload;
		},
		update: (state, action: PayloadAction<FundingRound>) => {
			for (const index in state.fundingRounds) {
				if (state.fundingRounds[index].id === action.payload.id && state.selectedFundingRound) {
					state.fundingRounds[index] = action.payload;
					state.selectedFundingRound = action.payload;
				}
			}
		},
		setFundingContractAddress: (state, action: PayloadAction<any>) => {
			for (const index in state.fundingRounds) {
				if (state.fundingRounds[index].id === action.payload.fundingRoundId && state.selectedFundingRound) {
					state.fundingRounds[index].fundingContractAddress = action.payload.contractAddress;
					state.selectedFundingRound.fundingContractAddress = action.payload.contractAddress;
				}
			}
		},
		setVestingContractAddress: (state, action: PayloadAction<any>) => {
			for (const index in state.fundingRounds) {
				if (state.fundingRounds[index].id === action.payload.fundingRoundId && state.selectedFundingRound) {
					state.fundingRounds[index].vestingContractAddress = action.payload.contractAddress;
					state.selectedFundingRound.vestingContractAddress = action.payload.contractAddress;
				}
			}
		},
		deleteFundingRound: (state, action: PayloadAction<number>) => {
			state.fundingRounds = state.fundingRounds.filter((t) => t.id !== action.payload);
		},
		selectFundingRound: (state, action: PayloadAction<FundingRound>) => {
			state.selectedFundingRound = action.payload;
		},
		addContractsData: (state, action: PayloadAction<any>) => {
			for (const index in state.fundingRounds) {
				if (state.fundingRounds[index].id === action.payload.id && state.selectedFundingRound) {
					if (action.payload.contractData.fundingData) {
						state.fundingRounds[index].fundingContractData = action.payload.contractData.fundingData;
						state.selectedFundingRound.fundingContractData = action.payload.contractData.fundingData;
					}
					if (action.payload.contractData.vestingData) {
						state.fundingRounds[index].vestingContractData = action.payload.contractData.vestingData;
						state.selectedFundingRound.vestingContractData = action.payload.contractData.vestingData;
					}
				}
			}
		},
		startFunding: (state, action: PayloadAction<any>) => {
			for (const index in state.fundingRounds) {
				if (state.fundingRounds[index].id === action.payload && state.selectedFundingRound) {
					state.fundingRounds[index].status = 'fundingReady';
					state.selectedFundingRound.status = 'fundingReady';
				}
			}
		},
		startVesting: (state, action: PayloadAction<any>) => {
			for (const index in state.fundingRounds) {
				if (state.fundingRounds[index].id === action.payload && state.selectedFundingRound) {
					state.fundingRounds[index].status = 'distributing';
					state.selectedFundingRound.status = 'distributing';
				}
			}
		},
	},
});

export const initFundingRounds = (featuredProjectId: number) => async (dispatch: AppDispatch) => {
	const listReponse = await fundingRoundFetches.listFetch(featuredProjectId);

	if (listReponse.status === 403) {
		dispatch(logout());
		return;
	}
	const data = await listReponse.json();

	if (data.status === true) {
		dispatch(get(data.data));
	}
};

export const getFundingRoundData = (fundingRoundId: number) => async (dispatch: AppDispatch) => {
	const getResponse = await fundingRoundFetches.getFetch(fundingRoundId);

	if (getResponse.status === 403) {
		dispatch(logout());
		return;
	}
	const data = await getResponse.json();

	if (data.status === true) {
		dispatch(selectFundingRound(data.data));
		dispatch(update(data.data));
		return data.data;
	}
};

// Action creators are generated for each case reducer function
export const {
	add,
	get,
	update,
	deleteFundingRound,
	selectFundingRound,
	setFundingContractAddress,
	setVestingContractAddress,
	addContractsData,
	startVesting,
	startFunding,
} = fundingRoundSlice.actions;

export default fundingRoundSlice.reducer;
