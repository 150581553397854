import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { featuredProjectFetches } from '../../service';
import { AppDispatch } from '../configureStore';
import { logout } from './configSlice';
import { FundingRound } from './fundingRoundSlice';

export interface FeaturedProjectData {
	featuredProject: FeaturedProject;
	teamMembers: TeamMember[];
}

export interface TeamMember {
	id?: number;
	featuredProjectId?: number;
	name: string;
	position: string;
	description: string;
	photoUrl?: string;
	linkedInUrl?: string;
	twitterUrl?: string;
	telegramUrl?: string;
}

export interface FeaturedProject {
	id?: number;
	currentFundingRoundId?: number;
	name: string;
	projectOverview: string;
	status?: string;
	coverUrl?: string;
	logoUrl?: string;
	isVisible: boolean;
	isPresentation: boolean;
	currentFundingRound?: FundingRound;
	teamMembers?: TeamMember[];
	coinSymbol?: string;
	websiteUrl?: string;
	twitterUrl?: string;
	discordUrl?: string;
	youtubeUrl?: string;
	telegramURl?: string;
	mediumUrl?: string;
	type?: string;
	urlName: string;
	showOffUrl?: string;
}

export interface FeaturedProjectState {
	featuredProjects: FeaturedProject[];
	selectedFeaturedProject: FeaturedProject | null;
}

const featuredProjectInitialState: FeaturedProjectState = {
	featuredProjects: [],
	selectedFeaturedProject: null,
};

export const featuredProjectSlice = createSlice({
	name: 'featuredProject',
	initialState: featuredProjectInitialState,
	reducers: {
		add: (state, action: PayloadAction<FeaturedProject>) => {
			state.featuredProjects.push(action.payload);
		},
		get: (state, action: PayloadAction<FeaturedProject[]>) => {
			state.featuredProjects = action.payload;
		},
		update: (state, action: PayloadAction<FeaturedProject>) => {
			for (const index in state.featuredProjects) {
				if (state.featuredProjects[index].id === action.payload.id && state.selectedFeaturedProject) {
					state.featuredProjects[index] = action.payload;
					state.selectedFeaturedProject = action.payload;
				}
			}
		},
		updateCoverPhoto: (state, action: PayloadAction<FeaturedProject>) => {
			for (const index in state.featuredProjects) {
				if (state.featuredProjects[index].id === action.payload.id && state.selectedFeaturedProject) {
					state.featuredProjects[index].coverUrl = action.payload.coverUrl;
					state.selectedFeaturedProject.coverUrl = action.payload.coverUrl;
				}
			}
		},
		updateLogoPhoto: (state, action: PayloadAction<FeaturedProject>) => {
			for (const index in state.featuredProjects) {
				if (state.featuredProjects[index].id === action.payload.id && state.selectedFeaturedProject) {
					state.featuredProjects[index].logoUrl = action.payload.logoUrl;
					state.selectedFeaturedProject.logoUrl = action.payload.logoUrl;
				}
			}
		},
		deleteFeaturedProject: (state, action: PayloadAction<number>) => {
			state.featuredProjects = state.featuredProjects.filter((t) => t.id !== action.payload);
		},
		deleteLogoPhoto: (state, action: PayloadAction<number>) => {
			for (const index in state.featuredProjects) {
				if (state.featuredProjects[index].id === action.payload && state.selectedFeaturedProject) {
					state.featuredProjects[index].logoUrl = '';
					state.selectedFeaturedProject.logoUrl = '';
				}
			}
		},
		deleteCoverPhoto: (state, action: PayloadAction<number>) => {
			for (const index in state.featuredProjects) {
				if (state.featuredProjects[index].id === action.payload && state.selectedFeaturedProject) {
					state.featuredProjects[index].coverUrl = '';
					state.selectedFeaturedProject.coverUrl = '';
				}
			}
		},
		selectFeaturedProject: (state, action: PayloadAction<FeaturedProject>) => {
			state.selectedFeaturedProject = action.payload;
		},
		setCurrentFundingRound: (state, action: PayloadAction<any>) => {
			for (const index in state.featuredProjects) {
				if (
					state.featuredProjects[index].id === action.payload.featuredProjectId &&
					state.selectedFeaturedProject
				) {
					state.featuredProjects[index].currentFundingRoundId = action.payload.fundingRoundId;
					state.featuredProjects[index].currentFundingRound = action.payload.newCurrentFundingRound;
					state.selectedFeaturedProject.currentFundingRoundId = action.payload.fundingRoundId;
					state.selectedFeaturedProject.currentFundingRound = action.payload.newCurrentFundingRound;
				}
			}
		},
		updateCurrentFundingRound: (state, action: PayloadAction<any>) => {
			for (const index in state.featuredProjects) {
				if (
					state.featuredProjects[index].id === action.payload.featuredProjectId &&
					state.selectedFeaturedProject
				) {
					state.featuredProjects[index].currentFundingRound = action.payload;
					state.selectedFeaturedProject.currentFundingRound = action.payload;
				}
			}
		},
		makePublic: (state, action: PayloadAction<any>) => {
			for (const index in state.featuredProjects) {
				if (state.featuredProjects[index].id === action.payload && state.selectedFeaturedProject) {
					state.featuredProjects[index].isVisible = true;
					state.selectedFeaturedProject.isVisible = true;
				}
			}
		},
	},
});

export const initFeaturedProjects = () => async (dispatch: AppDispatch) => {
	const listReponse = await featuredProjectFetches.listFetch();

	if (listReponse.status === 403) {
		dispatch(logout());
		return;
	}
	const data = await listReponse.json();

	if (data.status === true) {
		dispatch(get(data.data));
	}
};

// Action creators are generated for each case reducer function
export const {
	add,
	get,
	update,
	updateCoverPhoto,
	updateLogoPhoto,
	deleteFeaturedProject,
	deleteLogoPhoto,
	deleteCoverPhoto,
	selectFeaturedProject,
	setCurrentFundingRound,
	makePublic,
	updateCurrentFundingRound,
} = featuredProjectSlice.actions;

export default featuredProjectSlice.reducer;
