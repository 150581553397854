import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { adminUserFetches } from '../../service/adminUserFetches';
import { AppDispatch } from '../configureStore';
import { logout } from './configSlice';

export interface AdminUser {
	id?: number;
	email: string;
	password?: string;
	adminUserRole?: string;
}

export interface AdminUserState {
	adminUsers: AdminUser[];
	selectedAdminUser: AdminUser | null;
}

const adminUserInitialState: AdminUserState = {
	adminUsers: [],
	selectedAdminUser: null,
};

export const adminUserSlice = createSlice({
	name: 'adminUser',
	initialState: adminUserInitialState,
	reducers: {
		add: (state, action: PayloadAction<AdminUser>) => {
			state.adminUsers.push(action.payload);
		},
		get: (state, action: PayloadAction<AdminUser[]>) => {
			state.adminUsers = action.payload;
		},
		update: (state, action: PayloadAction<AdminUser>) => {
			for (const index in state.adminUsers) {
				if (state.adminUsers[index].id === action.payload.id && state.selectedAdminUser) {
					state.adminUsers[index] = action.payload;
					state.selectedAdminUser = action.payload;
				}
			}
		},
		deleteAdminUser: (state, action: PayloadAction<number>) => {
			state.adminUsers = state.adminUsers.filter((t) => t.id !== action.payload);
		},
		selectAdminUser: (state, action: PayloadAction<AdminUser>) => {
			state.selectedAdminUser = action.payload;
		},
	},
});

export const initAdminUsers = () => async (dispatch: AppDispatch) => {
	const listReponse = await adminUserFetches.listFetch();

	if (listReponse.status === 403) {
		await dispatch(logout());
		return;
	}
	const data = await listReponse.json();

	if (data.status === true) {
		await dispatch(get(data.data));
	}
};

// Action creators are generated for each case reducer function
export const { add, get, update, deleteAdminUser, selectAdminUser } = adminUserSlice.actions;

export default adminUserSlice.reducer;
