import { Configuration } from '../config/Config';
import { AdminUser } from '../redux/features/adminUserSlice';
import { AdminUserService } from './url/adminUserService';

export const adminUserFetches = {
	addFetch: async (adminUser: AdminUser) => {
		const response = await fetch(`${Configuration.baseUrl}${AdminUserService.CREATE_ADMIN_USER}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				...adminUser,
			}),
		});
		return await response;
	},

	authenticateFetch: async (adminUser: AdminUser) => {
		const response = await fetch(`${Configuration.baseUrl}${AdminUserService.AUTHENTICATE_ADMIN_USER}`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
			},
			body: JSON.stringify({
				...adminUser,
			}),
		});
		return await response.json();
	},

	getFetch: async (id?: string) => {
		let getUrl = `${Configuration.baseUrl}${AdminUserService.GET_ADMIN_USER}`;

		if (id !== undefined) {
			getUrl = `${getUrl}/${id}`;
		}

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},

	updateFetch: async (adminUser: AdminUser) => {
		const response = await fetch(`${Configuration.baseUrl}${AdminUserService.UPDATE_ADMIN_USER}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				...adminUser,
			}),
		});
		return await response;
	},

	removeFetch: async (id: number) => {
		const response = await fetch(`${Configuration.baseUrl}${AdminUserService.REMOVE_ADMIN_USER}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				id,
			}),
		});
		return await response;
	},

	listFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${AdminUserService.LIST_ADMIN_USERS}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},
};
