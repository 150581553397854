import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Statistic {
	id?: number;
	metamaskWalletsCreated: number;
	proxyWalletsCreated: number;
	emailWalletsCreated: number;
	featuredProjectsAdded: number;
	fundingRoundsCompleted: number;
	moneyAmountRaised: number;
	createdAt?: Date;
}

export interface TreasuryWallet {
	treasuryWalletAddress: string;
	treasuryWalletAmount: string;
	USDCAmount: string;
	supportedTokensAmounts: [];
}

export interface Marketplace {
	marketplaceAddress: string;
	platformFee: string;
	treasuryAddress: string;
	// payableTokens: [];
	payableToken: string;
}

export interface CompanyWallet {
	companyWalletAddress: string;
	companyWalletAmountMatic: number;
	companyWalletAmountEthereum: number;
	companyWalletAmountBinance: number;
	companyWalletAmountBinanceTestnet: number;
}

export interface SupportMessage {
	id?: number;
	email: string;
	name: string;
	message: string;
	isViewed: boolean;
}

export interface BlockchainInfo {
	label: string;
	chainId: number;
	stableCoins: { label: string; address: string }[];
}

export interface ConfigState {
	drawerOpen: boolean;
	loggedIn: boolean;
}

const configInitialState: ConfigState = {
	drawerOpen: false,
	loggedIn: false,
};

export const configSlice = createSlice({
	name: 'config',
	initialState: configInitialState,
	reducers: {
		setDrawerOpen: (state, action: PayloadAction<boolean>) => {
			state.drawerOpen = action.payload;
		},
		setLoggedIn: (state, action: PayloadAction<boolean>) => {
			state.loggedIn = action.payload;
		},
		logout: (state) => {
			// From here we can take action only at this "config" state
			// But, as we have taken care of this particular "logout" action
			// in rootReducer, we can use it to CLEAR the complete Redux Store's state
			localStorage.clear();
		},
	},
});

// Action creators are generated for each case reducer function
export const { setDrawerOpen, setLoggedIn, logout } = configSlice.actions;

export default configSlice.reducer;
