// prettier-ignore
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '../hooks/hooks';
import { RootState } from '../redux/configureStore';
import { logout } from '../redux/features/configSlice';
import { deleteProject, selectProject } from '../redux/features/projectSlice';
import { router } from '../Router';
import { projectFetches } from '../service';

export function ProjectsTable() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const projectsList = useSelector((state: RootState) => state.projectReducer.projects);

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	const ActionButtonsRenderer = (props: any) => (
		<strong>
			<IconButton
				aria-label="Edit"
				sx={{ color: 'green', ':hover': { bgcolor: '#dcdefc' } }}
				onClick={async (e) => {
					dispatch(selectProject(props.data));
					history.push(router.getProject({ id: props.value }).$);
				}}
			>
				<UpdateIcon />
			</IconButton>
			<IconButton
				aria-label="Delete"
				sx={{ color: 'red', ':hover': { bgcolor: '#dcdefc' } }}
				onClick={async (e) => {
					if (props.value) {
						const removeResponse = await projectFetches.removeFetch(props.value as number);

						if (removeResponse.status === 403) {
							await dispatch(logout());
							return;
						}
						const data = await removeResponse.json();

						if (data.status === true) {
							await dispatch(deleteProject(props.value as number));
						} else {
							setServerErrorMessage(`${data.error}`);
						}
					}
				}}
			>
				<DeleteIcon />
			</IconButton>
		</strong>
	);

	const columns = [
		{ headerName: 'First Name', field: 'firstName', sortable: true, filter: true, floatingFilter: true },
		{ headerName: 'Last Name', field: 'lastName', sortable: true, filter: true, floatingFilter: true },
		{ headerName: 'Project Name', field: 'projectName', sortable: true, filter: true, floatingFilter: true },
		{
			headerName: 'Contact Email',
			field: 'email',
			sortable: true,
			filter: true,
			floatingFilter: true,
		},
		{
			headerName: 'Website',
			field: 'website',
			sortable: true,
			filter: true,
			floatingFilter: true,
		},
		{
			headerName: 'Is Visible',
			field: 'isVisible',
			sortable: true,
			filter: true,
			floatingFilter: true,
			cellStyle: (params: any) => {
				return params.value === true ? { color: 'green' } : { color: 'red' };
			},
		},
		{
			headerName: 'Edit / Delete',
			cellStyle: { border: 'none' },
			field: 'id',
			cellRenderer: 'actionRenderer',
		},
	];

	return (
		<AgGridReact
			columnDefs={columns}
			rowData={projectsList}
			rowSelection="single"
			pagination={true}
			paginationAutoPageSize={true}
			frameworkComponents={{
				actionRenderer: ActionButtonsRenderer,
			}}
		/>
	);
}
