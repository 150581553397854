import { useTheme } from '@mui/material';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { Statistic } from '../redux/features/configSlice';

import { tokens } from '../theme';

interface Props {
	statisticsList: Statistic[];
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

export function BarChartStatisticsMonths({ statisticsList }: Props) {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const labels = moment.months();
	const dataConfig = {
		labels: labels,
		datasets: [
			{
				label: 'Funding Rounds',
				data: statisticsList.map((statistic) => {
					return statistic.fundingRoundsCompleted;
				}),
				backgroundColor: 'rgba(153, 102, 255, 0.2)',
				borderColor: 'rgb(153, 102, 255)',
				borderWidth: 1,
				// barThickness: 18,
			},
			{
				label: 'Featured Projects',
				data: statisticsList.map((statistic) => {
					return statistic.featuredProjectsAdded;
				}),
				backgroundColor: 'rgba(54, 162, 235, 0.2)',
				borderColor: 'rgb(54, 162, 235)',
				borderWidth: 1,
				// barThickness: 18,
			},
			{
				label: 'Metamask Wallets',
				data: statisticsList.map((statistic) => {
					return statistic.metamaskWalletsCreated;
				}),
				backgroundColor: 'rgba(255, 99, 132, 0.2)',
				borderColor: 'rgb(255, 99, 132)',
				borderWidth: 1,
				// barThickness: 18,
			},
			{
				label: 'Proxy Wallets',
				data: statisticsList.map((statistic) => {
					return statistic.proxyWalletsCreated;
				}),
				backgroundColor: 'rgba(255, 159, 64, 0.2)',
				borderColor: 'rgb(255, 159, 64)',
				borderWidth: 1,
				// barThickness: 18,
			},
			{
				label: 'Email Accounts',
				data: statisticsList.map((statistic) => {
					return statistic.emailWalletsCreated;
				}),
				backgroundColor: 'rgba(255, 205, 86, 0.2)',
				borderColor: 'rgb(255, 205, 86)',
				borderWidth: 1,
				// barThickness: 18,
			},
			{
				label: 'Money Amount',
				data: statisticsList.map((statistic) => {
					return statistic.moneyAmountRaised;
				}),
				backgroundColor: 'rgba(75, 192, 192, 0.2)',
				borderColor: 'rgb(75, 192, 192)',
				borderWidth: 1,
				// barThickness: 18,
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
				labels: { color: colors.grey[100] },
			},
		},
		scales: {
			x: {
				ticks: {
					major: {
						enabled: true,
					},
					color: colors.grey[100],
					font: function (context: any) {
						if (context.tick && context.tick.major) {
							return {
								weight: 'bold',
							};
						}
					},
				},
				grid: { color: colors.grey[100], drawOnChartArea: false },
			},
			y: {
				ticks: {
					major: {
						enabled: true,
					},
					color: colors.grey[100],
					font: function (context: any) {
						if (context.tick && context.tick.major) {
							return {
								weight: 'bold',
							};
						}
					},
				},
				grid: { color: colors.grey[100], drawOnChartArea: false },
				color: colors.grey[100],
			},
		},
	};

	return <Bar width={'100%'} height={'40%'} options={options} data={dataConfig} />;
}
