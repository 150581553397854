import { Configuration } from '../config/Config';
import { ChainService } from './url';

export const chainFetches = {
	listFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${ChainService.LIST_CHAINS}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},
};
