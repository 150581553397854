import { Box, FormControlLabel, Switch, TextField, useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from 'react-typesafe-routes';
import Header from '../../components/global/Header';
import { useDispatch } from '../../hooks/hooks';
import { logout, SupportMessage } from '../../redux/features/configSlice';
import { router } from '../../Router';
import { supportMessageFetches } from '../../service';

export function GetSupportMessagePage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const dispatch = useDispatch();

	const { id } = useRouteParams(router.getProject);

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const [selectedSupportMessage, setSelectedSupportMessage] = React.useState<SupportMessage>();
	const [checkViewed, setCheckViewed] = React.useState({ checked: selectedSupportMessage?.isViewed });

	const getSupportMessage = async (id: number) => {
		const getResponse = await supportMessageFetches.getFetch(id);

		if (getResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await getResponse.json();

		if (data.status === true) {
			setSelectedSupportMessage(data.data);
		} else {
			setServerErrorMessage(`${data.error}`);
		}
	};

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			if (id) {
				getSupportMessage(id);
			}
		}
	}, [history]);

	const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckViewed({ ...checkViewed, [event.target.name]: event.target.checked });
	};

	return selectedSupportMessage ? (
		<Box m="20px">
			<Box sx={{ gridColumn: 'span 4' }} display="flex" justifyContent="flex-start">
				<Header title="SUPPORT MESSAGE INFO" subtitle="Support Message data " />
				<FormControlLabel
					control={
						<Switch
							checked={checkViewed.checked}
							// onChange={handleCheckChange}
							name="checked"
							color="success"
						/>
					}
					label="Visible"
				/>
			</Box>
			<Formik onSubmit={() => {}} initialValues={selectedSupportMessage!}>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Email"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email}
								name="email"
								error={!!touched.email && !!errors.email}
								helperText={touched.email && errors.email}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.name}
								name="name"
								error={!!touched.name && !!errors.name}
								helperText={touched.name && errors.name}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Message"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.message}
								name="message"
								error={!!touched.message && !!errors.message}
								helperText={touched.message && errors.message}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	) : null;
}
