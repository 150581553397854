import { Box } from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { SupportMessagesTable } from '../../components';
import Header from '../../components/global/Header';
import { useDispatch } from '../../hooks/hooks';
import { logout, SupportMessage } from '../../redux/features/configSlice';
import { router } from '../../Router';
import { supportMessageFetches } from '../../service';

export function ListSupportMessagesPage() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const [supportMessagesList, setSupportMessagesList] = React.useState<SupportMessage[]>([]);

	const initSupportMessages = async () => {
		const listResponse = await supportMessageFetches.listFetch();

		if (listResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const listData = await listResponse.json();

		if (listData.status === true) {
			setSupportMessagesList(listData.data);
		} else {
			setServerErrorMessage(`${listData.error}`);
		}
	};

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			initSupportMessages();
		}
	}, [history]);

	return (
		<Box m="20px">
			<Header title="SUPPORT MESSAGES LIST" />
			<Box m="40px 0 0 0" height="78vh" className="ag-theme-alpine">
				<SupportMessagesTable tableList={supportMessagesList} />
			</Box>
		</Box>
	);
}
