import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
	Autocomplete,
	Box,
	Button,
	Divider,
	Stack,
	Tab,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ethers } from 'ethers';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from 'react-typesafe-routes';
import * as yup from 'yup';
import { router } from '../../Router';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { FundingRoundContract } from '../../components/FundingRoundContract';
import { Loader } from '../../components/Loader';
import Header from '../../components/global/Header';
import { Configuration } from '../../config/Config';
import { getBlockchainsInfoArray } from '../../config/middleware/getBlockchainsStableCoins';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { Chain, initChains } from '../../redux/features/chainSlice';
import { logout } from '../../redux/features/configSlice';
import { updateCurrentFundingRound } from '../../redux/features/featuredProjectSlice';
import {
	FundingRound,
	FundingRoundData,
	OverviewFundingRound,
	startFunding,
	startVesting,
	update,
} from '../../redux/features/fundingRoundSlice';
import { fundingRoundFetches } from '../../service';
import { tokens } from '../../theme';

export function GetFundingRoundPage(props: any) {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const dispatch = useDispatch();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	// get funding round if from route parameters
	const { fundingRoundId } = useRouteParams(router.getFundingRound);

	// setting server and validation errors
	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const [validationStartFundingErrors, setValidationStartFundingErrors] = React.useState({
		statusError: '',
		fundingContractAddressError: '',
		vestingContractAddressError: '',
	});
	const [validationStartVestingErrors, setValidationStartVestingErrors] = React.useState({
		vestingPeriodError: '',
		statusError: '',
		fundingContractAddressError: '',
		vestingContractAddressError: '',
	});
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const [action, setAction] = React.useState(() => () => {}); // Default action, does nothing
	const [actionName, setActionName] = React.useState('');
	const [onMarketplace, setOnMarketplace] = React.useState<boolean>(false);

	// get selected funding round,selected featured project from redux store
	const selectedFundingRound = useSelector((state: RootState) => state.fundingRoundReducer.selectedFundingRound);
	const selectedFeaturedProject = useSelector(
		(state: RootState) => state.featuredProjectReducer.selectedFeaturedProject
	);

	// get extra data list from redux store
	const supportedTokenList = useSelector((state: RootState) => state.supportedTokenReducer.supportedTokens);
	const chainsList = useSelector((state: RootState) => state.chainReducer.chains);

	// get stable coins and supported tokens for selected  chain
	const getStableCoinsByChainId = (chainId: number | null | undefined) => {
		const blockChainsInfoList = getBlockchainsInfoArray();
		const coinsPerChain = blockChainsInfoList.find((info) => info.chainId === chainId);
		return coinsPerChain ? coinsPerChain.stableCoins : [];
	};

	const getSupportedTokensByChainId = (id: number | null | undefined) =>
		id ? supportedTokenList.filter((token: any) => token.chainId === id) : [];

	// use filtered values when changing chain
	const [filteredSupportedTokensByChain, setFilteredSupportedTokensByChain] = React.useState<any[] | null>(
		getSupportedTokensByChainId(selectedFundingRound?.vestingChainId)
	);
	const [filteredStableCoinsByChain, setFilteredStableCoinsByChain] = React.useState<any[] | null>(
		getStableCoinsByChainId(selectedFundingRound?.fundingChain?.chainId)
	);

	React.useEffect(() => {
		// verify if logged in
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			// initialize chain list
			if (chainsList === null || chainsList === undefined || chainsList.length === 0) {
				dispatch(initChains());
			}
		}
	}, [history]);

	// desctructuring data for form initial values
	const { overview, ...fundingRound } = { ...selectedFundingRound };

	// formik initial values
	const formDataInitialValues: FundingRoundData = {
		fundingRound: {
			...fundingRound,
		} as FundingRound,

		overview: {
			...selectedFundingRound?.overview,
		} as OverviewFundingRound,
	};

	// tab for navigation
	const [selectedTab, setSelectedTab] = React.useState('1');

	// handle funding round update
	const handleUpdateFormSubmit = async (values: FundingRoundData) => {
		const fundingRoundDataToBackend = { ...values };

		// execute update request to backend and update data in redux store
		const updateResponse = await fundingRoundFetches.updateFetch(fundingRoundDataToBackend);

		if (updateResponse.status === 403) {
			dispatch(logout());
			return;
		}
		const data = await updateResponse.json();

		if (data.status === true) {
			// get updated funding round from response
			const fundingRoundToAddToStore = data.data;

			// set fundingChain and vestingChain
			const fundingChainDataToStore = chainsList.filter((chain) => chain.id === data.data.fundingChainId);
			fundingRoundToAddToStore.fundingChain = fundingChainDataToStore[0];
			const vestingChainDataToStore = chainsList.filter((chain) => chain.id === data.data.vestingChainId);
			fundingRoundToAddToStore.vestingChain = vestingChainDataToStore[0];

			// set updated funding round,overview,chain data to redux store
			dispatch(update(fundingRoundToAddToStore));

			// check if updated funding round is current funding round and update the current funding round for the selected Featured Project
			if (selectedFeaturedProject?.currentFundingRound?.id === fundingRoundId) {
				dispatch(updateCurrentFundingRound(fundingRoundToAddToStore));
			}

			// go to selected featured project page
			// history.push(router.listFundingRounds(props.match.params.featuredProjectId).$);
			history.push(router.getFeaturedProject({ id: props.match.params.featuredProjectId }).$);
		} else {
			setServerErrorMessage(data.error.value);
		}
	};

	// handle updating funding round to fundingReady
	const handleStartFunding = async () => {
		setIsLoading(true);
		if (validateStartFunding(selectedFundingRound!)) {
			const fundingResponse = await fundingRoundFetches.startFundingFetch(fundingRoundId!);

			setValidationStartFundingErrors({
				statusError: '',
				fundingContractAddressError: '',
				vestingContractAddressError: '',
			});

			if (fundingResponse.status === 403) {
				dispatch(logout());
				return;
			}
			const data = await fundingResponse.json();

			if (data.status === true) {
				dispatch(startFunding(fundingRoundId));
				setIsLoading(false);
				if (isLoading === false) {
					history.push(router.getFeaturedProject({ id: props.match.params.featuredProjectId }).$);
				}
			} else {
				setServerErrorMessage(data.error.value);
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
		}
	};

	// handle updating funding round to vesting
	const handleStartVesting = async () => {
		setIsLoading(true);
		if (validateStartVesting(selectedFundingRound!)) {
			const vestingResponse = await fundingRoundFetches.startVestingFetch(fundingRoundId!);

			setValidationStartVestingErrors({
				vestingPeriodError: '',
				statusError: '',
				fundingContractAddressError: '',
				vestingContractAddressError: '',
			});

			if (vestingResponse.status === 403) {
				dispatch(logout());
				return;
			}
			const data = await vestingResponse.json();

			if (data.status === true) {
				dispatch(startVesting(fundingRoundId));
				setIsLoading(false);
				if (isLoading === false) {
					history.push(router.getFeaturedProject({ id: props.match.params.featuredProjectId }).$);
				}
			} else {
				setServerErrorMessage(data.error.value);
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
		}
	};

	// handle add round contract to marketplace
	const handleAddContractToMarketplace = async () => {
		setIsLoading(true);
		const addContractToMarketplaceResponse = await fundingRoundFetches.addContractToMarketplaceFetch(
			fundingRoundId!
		);

		if (addContractToMarketplaceResponse.status === 403) {
			dispatch(logout());
			return;
		}
		const data = await addContractToMarketplaceResponse.json();

		if (data.status === true) {
			setIsLoading(false);
			setOnMarketplace(true);
			if (isLoading === false) {
				history.push(router.getFeaturedProject({ id: props.match.params.featuredProjectId }).$);
			}
		} else {
			setServerErrorMessage(data.error.value);
			setIsLoading(false);
		}
	};

	// handle tab navigation selection
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
	};

	// validating data to start funding
	const validateStartFunding = (values: FundingRound) => {
		const errors = {
			statusError: '',
			fundingContractAddressError: '',
			vestingContractAddressError: '',
		};

		if (values.status !== 'signUpEnded') {
			errors.statusError = 'Sign up Ended status is required!';
		}
		if (
			values.fundingContractAddress === null ||
			values.fundingContractAddress === undefined ||
			values.fundingContractAddress === ''
		) {
			errors.fundingContractAddressError = 'Funding Contract Address is required!';
		}
		if (
			values.vestingContractAddress === null ||
			values.vestingContractAddress === undefined ||
			values.vestingContractAddress === ''
		) {
			errors.vestingContractAddressError = 'Vesting Contract Address is required!';
		}

		// set errors
		if (errors.statusError || errors.fundingContractAddressError || errors.vestingContractAddressError) {
			setValidationStartFundingErrors(errors);
			return false;
		}
		return true;
	};

	// validanting data to start vesting
	const validateStartVesting = (values: FundingRound) => {
		const errors = {
			vestingPeriodError: '',
			statusError: '',
			fundingContractAddressError: '',
			vestingContractAddressError: '',
		};

		if (!values.vestingPeriod) {
			errors.vestingPeriodError = 'Vesting Period is required!';
		}
		if (values.status !== 'waiting') {
			errors.statusError = 'Waiting status is required!';
		}
		if (
			values.fundingContractAddress === null ||
			values.fundingContractAddress === undefined ||
			values.fundingContractAddress === ''
		) {
			errors.fundingContractAddressError = 'Funding Contract Address is required!';
		}
		if (
			values.vestingContractAddress === null ||
			values.vestingContractAddress === undefined ||
			values.vestingContractAddress === ''
		) {
			errors.vestingContractAddressError = 'Vesting Contract Address is required!';
		}

		// set errors
		if (
			errors.vestingPeriodError ||
			errors.statusError ||
			errors.fundingContractAddressError ||
			errors.vestingContractAddressError
		) {
			setValidationStartVestingErrors(errors);
			return false;
		}
		return true;
	};

	// formik validation schema
	const formDataValidationSchema = yup.object().shape({
		fundingRound: yup.object().shape({
			name: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			targetAmount: yup.string().required('required'),
			fundingChainId: yup.string().required('required').nullable(),
			vestingChainId: yup.string().required('required').nullable(),
			dollarTargetAmount: yup.string().required('required'),
			maxAllocation: yup.string().required('required'),
			minAllocation: yup.string().required('required'),
			status: yup.string().required('required'),
			signUpDeadline: yup.date().optional().nullable(),
			initialMarketCap: yup.string().required('required'),
			initialTokenSupply: yup.string().required('required'),
			pricePerToken: yup.string().required('required'),
			vestingDuration: yup.string().optional(),
			signUpStartDate: yup.date().optional().nullable(),
			vestingStartDate: yup.date().optional().nullable(),
			tokenForSaleAddress: yup
				.string()
				.required('required')
				.test({
					name: 'addressError',
					message: 'invalid address',
					test: (value: any) => ethers.utils.isAddress(value!),
				}),
			stableCoinAddress: yup
				.string()
				.required('required')
				.test({
					name: 'addressError',
					message: 'invalid address',
					test: (value: any) => ethers.utils.isAddress(value!),
				}),
			vestingPeriod: yup.number().optional().nullable().integer(),
			startRedeemPercentage: yup.number().optional().nullable().integer(),
			onePagerUrl: yup.string().url('Must be a valid URL').optional(),
			whitePaperUrl: yup.string().url('Must be a valid URL').optional(),
			motto: yup.string().optional(),
		}),
		overview: yup.object().shape({
			highlights: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			product: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			problem: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			solution: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			businessModel: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			market: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			investors: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		}),
	});

	const handleConfirmation = (confirmedAction: any, actionName: string) => {
		setAction(() => confirmedAction);
		setActionName(actionName);
		// Open the dialog
		setIsOpen(true);
	};

	const handleConfirm = () => {
		// Implement the logic to make the project public
		action(); // Perform the action associated with the button
		// Close the dialog
		setIsOpen(false);
	};

	const handleCancel = () => {
		// Close the dialog without making the project public
		setIsOpen(false);
	};

	return selectedFundingRound ? (
		<Box>
			{isLoading ? <Loader open={isLoading} /> : null}
			<TabContext value={selectedTab}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary">
						<Tab
							iconPosition="start"
							icon={<ArrowBackIcon />}
							aria-label="selected-featured-project"
							label={`Featured Project - ${selectedFeaturedProject?.name}`}
							onClick={() => {
								history.push(router.getFeaturedProject({ id: props.match.params.featuredProjectId }).$);
							}}
						/>
						<Tab label="Info" value="1" />
						<Tab label="Details Contract" value="2" />
					</TabList>
				</Box>
				<TabPanel value="1">
					<Box>
						<Box sx={{ gridColumn: 'span 4' }} display="flex" justifyItems="flex-start" gap="10px">
							<Header
								title="FUNDING ROUND INFO"
								subtitle={`Update Funding Round - ${selectedFundingRound.name}`}
							/>
							<Button
								variant="contained"
								color="secondary"
								size="large"
								onClick={() =>
									handleConfirmation(
										handleStartFunding,
										'Are you sure you want to start funding this project?'
									)
								}
								sx={{ alignSelf: 'center' }}
								disabled={selectedFundingRound?.status === 'signUpEnded' ? false : true}
							>
								Start Funding
							</Button>
							<Button
								variant="contained"
								color="secondary"
								size="large"
								onClick={() =>
									handleConfirmation(
										handleStartVesting,
										'Are you sure you want to start vesting this project?'
									)
								}
								sx={{ alignSelf: 'center' }}
								disabled={selectedFundingRound?.status === 'waiting' ? false : true}
							>
								Start Vesting
							</Button>
							<Button
								variant="contained"
								color="secondary"
								size="large"
								onClick={() =>
									handleConfirmation(
										handleAddContractToMarketplace,
										'Are you sure you want to add contract to marketplace?'
									)
								}
								sx={{ alignSelf: 'center' }}
								disabled={
									(selectedFundingRound?.status === 'distributing' ||
										selectedFundingRound?.status === 'waiting') &&
									onMarketplace === false
										? false
										: true
								}
							>
								Add Contract to Marketplace
							</Button>
							<ConfirmationDialog
								open={isOpen}
								onClose={handleCancel}
								onConfirm={handleConfirm}
								content={actionName}
							/>
							<Typography
								variant="h6"
								sx={{ color: colors.redAccent[500], alignSelf: 'center', width: '38%' }}
							>
								{validationStartFundingErrors.statusError
									? `-${validationStartFundingErrors.statusError}-`
									: null}
								{validationStartFundingErrors.fundingContractAddressError
									? `-${validationStartFundingErrors.fundingContractAddressError}-`
									: null}
								{validationStartFundingErrors.vestingContractAddressError
									? `-${validationStartFundingErrors.vestingContractAddressError}-`
									: null}
								{validationStartVestingErrors.vestingPeriodError
									? `-${validationStartVestingErrors.vestingPeriodError}-`
									: null}
								{validationStartVestingErrors.statusError
									? `-${validationStartVestingErrors.statusError}-`
									: null}
								{validationStartVestingErrors.fundingContractAddressError
									? `-${validationStartVestingErrors.fundingContractAddressError}-`
									: null}
								{validationStartVestingErrors.vestingContractAddressError
									? `-${validationStartVestingErrors.vestingContractAddressError}-`
									: null}
								{serverErrorMessage ? `-${serverErrorMessage}-` : null}
							</Typography>
						</Box>
						<Divider sx={{ gridColumn: 'span 4', mt: '30px' }} />
						<Formik
							onSubmit={handleUpdateFormSubmit}
							initialValues={formDataInitialValues}
							validationSchema={formDataValidationSchema}
						>
							{({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
								<form onSubmit={handleSubmit}>
									{/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
									{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
									<Box
										mt="40px"
										display="grid"
										gap="30px"
										gridTemplateColumns="repeat(4,minmax(0,1fr))"
										sx={{
											'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
										}}
									>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Funding Round Name"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.name}
											name="fundingRound.name"
											error={!!touched.fundingRound?.name && !!errors.fundingRound?.name}
											helperText={touched.fundingRound?.name && errors.fundingRound?.name}
											sx={{ gridColumn: 'span 2' }}
										/>
										<Autocomplete
											options={chainsList}
											disableClearable
											selectOnFocus
											clearOnBlur
											onChange={(e, value) => {
												setFieldValue('fundingRound.fundingChainId', value.id);
												setFilteredStableCoinsByChain(getStableCoinsByChainId(value.chainId));
												setFieldValue('fundingRound.stableCoinAddress', '');
											}}
											autoHighlight
											sx={{ gridColumn: 'span 2' }}
											getOptionLabel={(option) => option.name}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											value={chainsList.find(
												(item: Chain) => item.id === values.fundingRound.fundingChainId
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													variant="filled"
													type="text"
													label={`Funding Chain: ${values.fundingRound.fundingChain!.name}`}
													onBlur={handleBlur}
													name="fundingRound.fundingChainId"
													error={
														!!touched.fundingRound?.fundingChainId &&
														!!errors.fundingRound?.fundingChainId
													}
													helperText={
														touched.fundingRound?.fundingChainId &&
														errors.fundingRound?.fundingChainId
													}
												/>
											)}
										/>
										<Autocomplete
											options={chainsList}
											disableClearable
											selectOnFocus
											clearOnBlur
											onChange={(e, value) => {
												setFieldValue('fundingRound.vestingChainId', value.id);
												setFilteredSupportedTokensByChain(
													getSupportedTokensByChainId(value.id)
												);
												setFieldValue('fundingRound.tokenForSaleAddress', '');
											}}
											autoHighlight
											sx={{ gridColumn: 'span 2' }}
											getOptionLabel={(option) => option.name}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											value={chainsList.find(
												(item: Chain) => item.id === values.fundingRound.vestingChainId
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													variant="filled"
													type="text"
													label={`Vesting Chain: ${values.fundingRound.vestingChain!.name}`}
													onBlur={handleBlur}
													name="fundingRound.vestingChainId"
													error={
														!!touched.fundingRound?.vestingChainId &&
														!!errors.fundingRound?.vestingChainId
													}
													helperText={
														touched.fundingRound?.vestingChainId &&
														errors.fundingRound?.vestingChainId
													}
												/>
											)}
										/>
										<Autocomplete
											options={filteredStableCoinsByChain ? filteredStableCoinsByChain : []}
											disableClearable
											selectOnFocus
											clearOnBlur
											onChange={(e, value) =>
												setFieldValue('fundingRound.stableCoinAddress', value.address)
											}
											autoHighlight
											sx={{ gridColumn: 'span 4' }}
											getOptionLabel={(option) => `${option.label}: ${option.address}`}
											value={
												filteredStableCoinsByChain?.find(
													(option) => option.address === values.fundingRound.stableCoinAddress
												) || null
											}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													variant="filled"
													type="text"
													label="Stable Coin Address"
													onBlur={handleBlur}
													name="fundingRound.stableCoinAddress"
													error={
														!!touched.fundingRound?.stableCoinAddress &&
														!!errors.fundingRound?.stableCoinAddress
													}
													helperText={
														touched.fundingRound?.stableCoinAddress &&
														errors.fundingRound?.stableCoinAddress
													}
												/>
											)}
										/>
										<Autocomplete
											options={
												filteredSupportedTokensByChain ? filteredSupportedTokensByChain : []
											}
											disableClearable
											selectOnFocus
											clearOnBlur
											onChange={(e, value) =>
												setFieldValue('fundingRound.tokenForSaleAddress', value.address)
											}
											autoHighlight
											sx={{ gridColumn: 'span 4' }}
											getOptionLabel={(option) => `${option.name}: ${option.address}`}
											value={
												filteredSupportedTokensByChain?.find(
													(option) =>
														option.address === values.fundingRound.tokenForSaleAddress
												) || null
											}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													variant="filled"
													type="text"
													label="Token For Sale Address"
													onBlur={handleBlur}
													name="fundingRound.tokenForSaleAddress"
													error={
														!!touched.fundingRound?.tokenForSaleAddress &&
														!!errors.fundingRound?.tokenForSaleAddress
													}
													helperText={
														touched.fundingRound?.tokenForSaleAddress &&
														errors.fundingRound?.tokenForSaleAddress
													}
												/>
											)}
										/>
										<Autocomplete
											options={Configuration.statusFundingRound}
											disableClearable
											selectOnFocus
											clearOnBlur
											onChange={(e, value) => setFieldValue('fundingRound.status', value)}
											autoHighlight
											sx={{ gridColumn: 'span 2' }}
											getOptionLabel={(option) => option}
											value={values.fundingRound.status}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													variant="filled"
													type="text"
													onBlur={handleBlur}
													label="Status"
													name="fundingRound.status"
													error={
														!!touched.fundingRound?.status && !!errors.fundingRound?.status
													}
													helperText={
														touched.fundingRound?.status && errors.fundingRound?.status
													}
												/>
											)}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="White Paper Url"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.whitePaperUrl}
											name="fundingRound.whitePaperUrl"
											error={
												!!touched.fundingRound?.whitePaperUrl &&
												!!errors.fundingRound?.whitePaperUrl
											}
											helperText={
												touched.fundingRound?.whitePaperUrl &&
												errors.fundingRound?.whitePaperUrl
											}
											sx={{ gridColumn: 'span 4' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Open Pager Url"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.onePagerUrl}
											name="fundingRound.onePagerUrl"
											error={
												!!touched.fundingRound?.onePagerUrl &&
												!!errors.fundingRound?.onePagerUrl
											}
											helperText={
												touched.fundingRound?.onePagerUrl && errors.fundingRound?.onePagerUrl
											}
											sx={{ gridColumn: 'span 4' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Motto"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.motto}
											name="fundingRound.motto"
											error={!!touched.fundingRound?.motto && !!errors.fundingRound?.motto}
											helperText={touched.fundingRound?.motto && errors.fundingRound?.motto}
											sx={{ gridColumn: 'span 4' }}
										/>
										<Divider sx={{ gridColumn: 'span 4' }} />
										<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
											Tokenomics
										</Typography>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Price per Token"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.pricePerToken}
											name="fundingRound.pricePerToken"
											error={
												!!touched.fundingRound?.pricePerToken &&
												!!errors.fundingRound?.pricePerToken
											}
											helperText={
												touched.fundingRound?.pricePerToken &&
												errors.fundingRound?.pricePerToken
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Target Amount"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.targetAmount}
											name="fundingRound.targetAmount"
											error={
												!!touched.fundingRound?.targetAmount &&
												!!errors.fundingRound?.targetAmount
											}
											helperText={
												touched.fundingRound?.targetAmount && errors.fundingRound?.targetAmount
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Dollar Target Amount"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.dollarTargetAmount}
											name="fundingRound.dollarTargetAmount"
											error={
												!!touched.fundingRound?.dollarTargetAmount &&
												!!errors.fundingRound?.dollarTargetAmount
											}
											helperText={
												touched.fundingRound?.dollarTargetAmount &&
												errors.fundingRound?.dollarTargetAmount
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Max Allocation"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.maxAllocation}
											name="fundingRound.maxAllocation"
											error={
												!!touched.fundingRound?.maxAllocation &&
												!!errors.fundingRound?.maxAllocation
											}
											helperText={
												touched.fundingRound?.maxAllocation &&
												errors.fundingRound?.maxAllocation
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Min Allocation"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.minAllocation}
											name="fundingRound.minAllocation"
											error={
												!!touched.fundingRound?.minAllocation &&
												!!errors.fundingRound?.minAllocation
											}
											helperText={
												touched.fundingRound?.minAllocation &&
												errors.fundingRound?.minAllocation
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Initial Market Cap"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.initialMarketCap}
											name="fundingRound.initialMarketCap"
											error={
												!!touched.fundingRound?.initialMarketCap &&
												!!errors.fundingRound?.initialMarketCap
											}
											helperText={
												touched.fundingRound?.initialMarketCap &&
												errors.fundingRound?.initialMarketCap
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Initial Token Supply"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.initialTokenSupply}
											name="fundingRound.initialTokenSupply"
											error={
												!!touched.fundingRound?.initialTokenSupply &&
												!!errors.fundingRound?.initialTokenSupply
											}
											helperText={
												touched.fundingRound?.initialTokenSupply &&
												errors.fundingRound?.initialTokenSupply
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<Stack spacing={3} sx={{ gridColumn: 'span 2' }}>
											<DesktopDatePicker
												inputFormat="MM/dd/yyyy"
												value={values.fundingRound.signUpStartDate}
												minDate={new Date()}
												onChange={(date) => setFieldValue('fundingRound.signUpStartDate', date)}
												renderInput={(params) => (
													<TextField
														fullWidth
														variant="filled"
														{...params}
														type="date"
														label="Sign Up Start Date"
														value={values.fundingRound.signUpStartDate}
														onBlur={handleBlur}
														name="fundingRound.signUpStartDate"
													/>
												)}
											/>
										</Stack>
										<Stack spacing={3} sx={{ gridColumn: 'span 2' }}>
											<DesktopDatePicker
												inputFormat="MM/dd/yyyy"
												value={values.fundingRound.signUpDeadline}
												minDate={new Date()}
												onChange={(date) => setFieldValue('fundingRound.signUpDeadline', date)}
												renderInput={(params) => (
													<TextField
														fullWidth
														variant="filled"
														{...params}
														type="date"
														label="Sign Up Deadline"
														value={values.fundingRound.signUpDeadline}
														onBlur={handleBlur}
														name="fundingRound.signUpDeadline"
													/>
												)}
											/>
										</Stack>
										<Divider sx={{ gridColumn: 'span 4' }} />
										<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
											Vesting
										</Typography>
										<Stack spacing={3} sx={{ gridColumn: 'span 2' }}>
											<DesktopDatePicker
												inputFormat="MM/dd/yyyy"
												value={values.fundingRound.vestingStartDate}
												minDate={new Date()}
												onChange={(date) =>
													setFieldValue('fundingRound.vestingStartDate', date)
												}
												renderInput={(params) => (
													<TextField
														fullWidth
														variant="filled"
														{...params}
														type="date"
														label="Vesting Start Date"
														value={values.fundingRound.vestingStartDate}
														onBlur={handleBlur}
														name="fundingRound.vestingStartDate"
													/>
												)}
											/>
										</Stack>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Vesting Duration ( text )"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.vestingDuration}
											name="fundingRound.vestingDuration"
											error={
												!!touched.fundingRound?.vestingDuration &&
												!!errors.fundingRound?.vestingDuration
											}
											helperText={
												touched.fundingRound?.vestingDuration &&
												errors.fundingRound?.vestingDuration
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Vesting Period ( number of days )"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.vestingPeriod ?? ''}
											name="fundingRound.vestingPeriod"
											error={
												!!touched.fundingRound?.vestingPeriod &&
												!!errors.fundingRound?.vestingPeriod
											}
											helperText={
												touched.fundingRound?.vestingPeriod &&
												errors.fundingRound?.vestingPeriod
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Start Redeem Percentage ( % )"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.fundingRound.startRedeemPercentage ?? ''}
											name="fundingRound.startRedeemPercentage"
											error={
												!!touched.fundingRound?.startRedeemPercentage &&
												!!errors.fundingRound?.startRedeemPercentage
											}
											helperText={
												touched.fundingRound?.startRedeemPercentage &&
												errors.fundingRound?.startRedeemPercentage
											}
											sx={{ gridColumn: 'span 2' }}
										/>

										<Divider sx={{ gridColumn: 'span 4' }} />
										<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
											Overview
										</Typography>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Highlights"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.overview?.highlights}
											name="overview.highlights"
											error={!!touched.overview?.highlights && !!errors.overview?.highlights}
											helperText={touched.overview?.highlights && errors.overview?.highlights}
											sx={{ gridColumn: 'span 4' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Product"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.overview?.product}
											name="overview.product"
											error={!!touched.overview?.product && !!errors.overview?.product}
											helperText={touched.overview?.product && errors.overview?.product}
											sx={{ gridColumn: 'span 4' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Problem"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.overview?.problem}
											name="overview.problem"
											error={!!touched.overview?.problem && !!errors.overview?.problem}
											helperText={touched.overview?.problem && errors.overview?.problem}
											sx={{ gridColumn: 'span 4' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Solution"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.overview?.solution}
											name="overview.solution"
											error={!!touched.overview?.solution && !!errors.overview?.solution}
											helperText={touched.overview?.solution && errors.overview?.solution}
											sx={{ gridColumn: 'span 4' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Business Model"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.overview?.businessModel}
											name="overview.businessModel"
											error={
												!!touched.overview?.businessModel && !!errors.overview?.businessModel
											}
											helperText={
												touched.overview?.businessModel && errors.overview?.businessModel
											}
											sx={{ gridColumn: 'span 4' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Market"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.overview?.market}
											name="overview.market"
											error={!!touched.overview?.market && !!errors.overview?.market}
											helperText={touched.overview?.market && errors.overview?.market}
											sx={{ gridColumn: 'span 4' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Investors"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.overview?.investors}
											name="overview.investors"
											error={!!touched.overview?.investors && !!errors.overview?.investors}
											helperText={touched.overview?.investors && errors.overview?.investors}
											sx={{ gridColumn: 'span 4' }}
										/>
										{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
									</Box>
									<Box display="flex" justifyContent="end" mt="20px">
										<Button type="submit" color="secondary" variant="contained">
											Update Funding Round
										</Button>
									</Box>
								</form>
							)}
						</Formik>
					</Box>
				</TabPanel>
				<TabPanel value="2">
					<FundingRoundContract />
				</TabPanel>
			</TabContext>
		</Box>
	) : null;
}
