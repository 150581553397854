import { Configuration } from '../config/Config';
import { Project } from '../redux/features/projectSlice';
import { ProjectService } from './url';

export const projectFetches = {
	listFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${ProjectService.LIST_PROJECTS}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},

	addFetch: async (project: Project) => {
		const response = await fetch(`${Configuration.baseUrl}${ProjectService.CREATE_PROJECT}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				...project,
			}),
		});
		return await response;
	},

	updateFetch: async (project: Project) => {
		const response = await fetch(`${Configuration.baseUrl}${ProjectService.UPDATE_PROJECT}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				...project,
			}),
		});
		return await response;
	},

	removeFetch: async (id: number) => {
		const response = await fetch(`${Configuration.baseUrl}${ProjectService.REMOVE_PROJECT}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				id,
			}),
		});
		return await response;
	},

	getProjectFetch: async (projectId?: string) => {
		let getUrl = `${Configuration.baseUrl}${ProjectService.GET_PROJECT}`;

		if (projectId !== undefined) {
			getUrl = `${getUrl}/${projectId}`;
		}

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},
};
