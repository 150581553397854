import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';

const Header = ({ title, subtitle, oversize: boolean }: any) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<Box mb="2px" mr="20px" sx={{ width: '25%' }}>
			<Typography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{ m: '0 0 5px 0' }}>
				{title}
			</Typography>
			<Typography variant="h5" color={colors.greenAccent[400]}>
				{subtitle}
			</Typography>
		</Box>
	);
};

export default Header;
