import { Configuration } from '../config/Config';
import { SupportMessageService } from './url';

export const supportMessageFetches = {
	listFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${SupportMessageService.LIST_SUPPORT_MESSAGES}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},

	getFetch: async (supportMessageId: number) => {
		let getUrl = `${Configuration.baseUrl}${SupportMessageService.GET_SUPPORT_MESSAGE}`;

		if (supportMessageId !== undefined) {
			getUrl = `${getUrl}/${supportMessageId}`;
		}

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},
};
