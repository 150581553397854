import { Link, Typography, useTheme } from '@mui/material';
import { tokens } from '../theme';

export function Copyright() {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<Typography variant="body2" sx={{ color: colors.primary[100], marginTop: 'auto', marginBottom: '20px' }}>
			{'Copyright © '}
			<Link color="inherit">Collective Pad</Link> {new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}
