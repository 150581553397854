import { Configuration } from '../config/Config';
import { StatiscticsService } from './url';

export const statisticsFetches = {
	getTotalFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${StatiscticsService.GET_TOTAL_STATISTICS}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},

	listForMonthsFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${StatiscticsService.LIST_MONTHS_STATISTICS}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},

	listStatisticMonthsUpriseFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${StatiscticsService.LIST_STATISTIC_MONTHS_UPRISE}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},
};
