import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button } from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { AdminUsersTable } from '../../components';
import Header from '../../components/global/Header';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { initAdminUsers } from '../../redux/features/adminUserSlice';
import { router } from '../../Router';

export function ListAdminUsersPage() {
	const history = useHistory();
	const adminUsersList = useSelector((state: RootState) => state.adminUserReducer.adminUsers);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			if (adminUsersList === null || adminUsersList === undefined || adminUsersList.length === 0) {
				dispatch(initAdminUsers());
			}
		}
	}, [history]);

	const handleRefresh = () => {
		dispatch(initAdminUsers());
	};

	return (
		<Box m="20px">
			<Box display={'flex'} justifyContent="flex-start" gap="2px">
				<Header title="ADMIN USERS LIST" />
				<Button
					variant="contained"
					startIcon={<RefreshIcon />}
					size="medium"
					onClick={handleRefresh}
					color="secondary"
				>
					Refresh
				</Button>

				<Button
					variant="contained"
					startIcon={<AddIcon />}
					size="medium"
					color="secondary"
					onClick={() => history.push(router.addAdminUser().$)}
				>
					Add
				</Button>
			</Box>
			<Box m="40px 0 0 0" height="78vh" className="ag-theme-alpine">
				<AdminUsersTable />
			</Box>
		</Box>
	);
}
