import { Configuration } from '../config/Config';
import { SupportedToken } from '../redux/features/supportedTokenSlice';
import { SupportedTokenService } from './url';

export const supportedTokenFetches = {
	listFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${SupportedTokenService.LIST_SUPPORTED_TOKENS}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},

	addFetch: async (formData: FormData) => {
		const response = await fetch(`${Configuration.baseUrl}${SupportedTokenService.CREATE_SUPPORTED_TOKEN}`, {
			method: 'POST',
			headers: {
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: formData,
		});
		return await response;
	},

	updatePhotoFetch: async (formData: FormData) => {
		const response = await fetch(`${Configuration.baseUrl}${SupportedTokenService.UPDATE_SUPPORTED_TOKEN}`, {
			method: 'POST',
			headers: {
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: formData,
		});
		return await response;
	},

	updateFetch: async (supportedToken: SupportedToken) => {
		const response = await fetch(`${Configuration.baseUrl}${SupportedTokenService.UPDATE_SUPPORTED_TOKEN}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				...supportedToken,
			}),
		});
		return await response;
	},

	removeFetch: async (id: number, logoUrl: string) => {
		const response = await fetch(`${Configuration.baseUrl}${SupportedTokenService.REMOVE_SUPPORTED_TOKEN}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				id,
				logoUrl,
			}),
		});
		return await response;
	},

	getFetch: async (supportedTokenId?: string) => {
		let getUrl = `${Configuration.baseUrl}${SupportedTokenService.GET_SUPPORTED_TOKEN}`;

		if (supportedTokenId !== undefined) {
			getUrl = `${getUrl}/${supportedTokenId}`;
		}

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},
};
