import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { Autocomplete, Box, Button, Divider, Stack, Tab, TextField, Typography, useMediaQuery } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ethers } from 'ethers';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { router } from '../../Router';
import Header from '../../components/global/Header';
import { getBlockchainsInfoArray } from '../../config/middleware/getBlockchainsStableCoins';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { initChains } from '../../redux/features/chainSlice';
import { logout } from '../../redux/features/configSlice';
import { FundingRound, FundingRoundData, OverviewFundingRound, add } from '../../redux/features/fundingRoundSlice';
import { fundingRoundFetches } from '../../service';

export function AddFundingRoundPage(props: any) {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const dispatch = useDispatch();

	// setting server and validation errors
	const [serverErrorMessage, setServerErrorMessage] = React.useState('');

	const selectedFeaturedProject = useSelector(
		(state: RootState) => state.featuredProjectReducer.selectedFeaturedProject
	);

	// get extra data list from redux store
	const chainsList = useSelector((state: RootState) => state.chainReducer.chains);
	const supportedTokenList = useSelector((state: RootState) => state.supportedTokenReducer.supportedTokens);

	// get stable coins and supported tokens for selected  chain
	const getStableCoinsByChainId = (chainId: number | null | undefined) => {
		const blockChainsInfoList = getBlockchainsInfoArray();
		const coinsPerChain = blockChainsInfoList.find((info) => info.chainId === chainId);
		return coinsPerChain ? coinsPerChain.stableCoins : [];
	};

	const getSupportedTokensByChainId = (id: number | null | undefined) =>
		id ? supportedTokenList.filter((token: any) => token.chainId === id) : [];

	// use filtered values when changing chain
	const [filteredSupportedTokensByChain, setFilteredSupportedTokensByChain] = React.useState<any[] | null>([]);
	const [filteredStableCoinsByChain, setFilteredStableCoinsByChain] = React.useState<any[] | null>([]);

	const formDataInitialValues: FundingRoundData = {
		fundingRound: {
			featuredProjectId: parseInt(props.match.params.featuredProjectId),
			name: '',
			targetAmount: '',
			fundingChainId: null,
			vestingChainId: null,
			dollarTargetAmount: '',
			maxAllocation: '',
			minAllocation: '',
			status: 'pending',
			signUpDeadline: null,
			tokenForSaleAddress: '',
			stableCoinAddress: '',
			initialMarketCap: '',
			initialTokenSupply: '',
			pricePerToken: '',
			vestingDuration: '',
			signUpStartDate: null,
			vestingStartDate: null,
			vestingPeriod: null,
			startRedeemPercentage: null,
			onePagerUrl: '',
			whitePaperUrl: '',
			motto: '',
		} as FundingRound,

		overview: {
			highlights: '',
			product: '',
			problem: '',
			solution: '',
			businessModel: '',
			market: '',
			investors: '',
		} as OverviewFundingRound,
	};

	// test data
	// tokenForSaleAddress: '0x55015821ad53c8EA60D4cABd080586DaB643c569'
	// tokenProviderAddress: '0xC672Da0CfaeE4Ee29CE7DCDE801D67A4CE58A02C'
	// stableCoinAddress: '0x6F4C286A0A5a738ee198Cc2605Cc3Ac9f21b6423'

	React.useEffect(() => {
		// verify if logged in
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			// initialize chain list
			if (chainsList === null || chainsList === undefined || chainsList.length === 0) {
				dispatch(initChains());
			}
		}
	}, [history]);

	// handle adding new funding round
	const handleAddFormSubmit = async (values: FundingRoundData) => {
		const fundingRoundDataToBackend: FundingRoundData = { ...values };

		// execute add request and add funding round to redux store
		const addResponse = await fundingRoundFetches.addFetch(fundingRoundDataToBackend);

		if (addResponse.status === 403) {
			dispatch(logout());
			return;
		}
		const data = await addResponse.json();

		if (data.status === true) {
			// get created funding round from response
			const fundingRoundToAddToStore = data.data;

			// set fundingChain and vestingChain
			const fundingChainDataToStore = chainsList.filter((chain) => chain.id === data.data.fundingChainId);
			fundingRoundToAddToStore.fundingChain = fundingChainDataToStore[0];
			const vestingChainDataToStore = chainsList.filter((chain) => chain.id === data.data.vestingChainId);
			fundingRoundToAddToStore.vestingChain = vestingChainDataToStore[0];

			// set created funding round and chain data to redux store
			dispatch(add(fundingRoundToAddToStore));

			// go back to selected featured project page
			history.push(router.getFeaturedProject({ id: props.match.params.featuredProjectId }).$);
		} else {
			setServerErrorMessage(data.error.value);
		}
	};

	const formDataValidationSchema = yup.object().shape({
		fundingRound: yup.object().shape({
			name: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			targetAmount: yup.string().required('required'),
			fundingChainId: yup.string().required('required').nullable(),
			vestingChainId: yup.string().required('required').nullable(),
			dollarTargetAmount: yup.string().required('required'),
			maxAllocation: yup.string().required('required'),
			minAllocation: yup.string().required('required'),
			status: yup.string().required('required'),
			signUpDeadline: yup.date().optional().nullable(),
			initialMarketCap: yup.string().required('required'),
			initialTokenSupply: yup.string().required('required'),
			pricePerToken: yup.string().required('required'),
			vestingDuration: yup.string().optional(),
			signUpStartDate: yup.date().optional().nullable(),
			vestingStartDate: yup.date().optional().nullable(),
			tokenForSaleAddress: yup
				.string()
				.required('required')
				.test({
					name: 'addressError',
					message: 'invalid address',
					test: (value: any) => ethers.utils.isAddress(value!),
				}),
			stableCoinAddress: yup
				.string()
				.required('required')
				.test({
					name: 'addressError',
					message: 'invalid address',
					test: (value: any) => ethers.utils.isAddress(value!),
				}),
			vestingPeriod: yup.number().optional().nullable().integer(),
			startRedeemPercentage: yup.number().optional().nullable().integer(),
			onePagerUrl: yup.string().url('Must be a valid URL').optional(),
			whitePaperUrl: yup.string().url('Must be a valid URL').optional(),
			motto: yup.string().optional(),
		}),
		overview: yup.object().shape({
			highlights: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			product: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			problem: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			solution: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			businessModel: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			market: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			investors: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		}),
	});

	return (
		<Box m="20px">
			<Tab
				iconPosition="start"
				icon={<ArrowBackIcon />}
				aria-label="selected-featured-project"
				label={`Featured Project - ${selectedFeaturedProject?.name}`}
				onClick={() => {
					history.push(router.getFeaturedProject({ id: props.match.params.featuredProjectId }).$);
				}}
			/>
			<Divider sx={{ gridColumn: 'span 4', marginBottom: '25px' }} />
			<Header title="ADD FUNDING ROUND " subtitle={`Create a New Funding Round`} />
			<Formik
				onSubmit={handleAddFormSubmit}
				initialValues={formDataInitialValues}
				validationSchema={formDataValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						{/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
						{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Funding Round Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.name}
								name="fundingRound.name"
								error={!!touched.fundingRound?.name && !!errors.fundingRound?.name}
								helperText={touched.fundingRound?.name && errors.fundingRound?.name}
								sx={{ gridColumn: 'span 2' }}
							/>
							<Autocomplete
								options={chainsList}
								disableClearable
								selectOnFocus
								clearOnBlur
								onChange={(e, value) => {
									setFieldValue('fundingRound.fundingChainId', value.id);
									setFilteredStableCoinsByChain(getStableCoinsByChainId(value.chainId));
									setFieldValue(
										'fundingRound.stableCoinAddress',
										getStableCoinsByChainId(value.chainId)[0].address
									);
								}}
								autoHighlight
								sx={{ gridColumn: 'span 2' }}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant="filled"
										type="text"
										label="Funding Chain"
										onBlur={handleBlur}
										name="fundingRound.fundingChainId"
										error={
											!!touched.fundingRound?.fundingChainId &&
											!!errors.fundingRound?.fundingChainId
										}
										helperText={
											touched.fundingRound?.fundingChainId && errors.fundingRound?.fundingChainId
										}
									/>
								)}
							/>
							<Autocomplete
								options={chainsList}
								disableClearable
								selectOnFocus
								clearOnBlur
								onChange={(e, value) => {
									setFieldValue('fundingRound.vestingChainId', value.id);
									setFilteredSupportedTokensByChain(getSupportedTokensByChainId(value.id));
									setFieldValue(
										'fundingRound.tokenForSaleAddress',
										getSupportedTokensByChainId(value.id)[0]
											? getSupportedTokensByChainId(value.id)[0].address
											: ''
									);
								}}
								autoHighlight
								sx={{ gridColumn: 'span 2' }}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant="filled"
										type="text"
										label="Vesting Chain"
										onBlur={handleBlur}
										name="fundingRound.vestingChainId"
										error={
											!!touched.fundingRound?.vestingChainId &&
											!!errors.fundingRound?.vestingChainId
										}
										helperText={
											touched.fundingRound?.vestingChainId && errors.fundingRound?.vestingChainId
										}
									/>
								)}
							/>
							<Autocomplete
								options={filteredStableCoinsByChain ? filteredStableCoinsByChain : []}
								disableClearable
								selectOnFocus
								clearOnBlur
								onChange={(e, value) => setFieldValue('fundingRound.stableCoinAddress', value.address)}
								autoHighlight
								sx={{ gridColumn: 'span 4' }}
								getOptionLabel={(option) => `${option.label}: ${option.address}`}
								value={
									filteredStableCoinsByChain?.find(
										(option) => option.address === values.fundingRound.stableCoinAddress
									) || null
								}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant="filled"
										type="text"
										label="Stable Coin Address"
										onBlur={handleBlur}
										name="fundingRound.stableCoinAddress"
										error={
											!!touched.fundingRound?.stableCoinAddress &&
											!!errors.fundingRound?.stableCoinAddress
										}
										helperText={
											touched.fundingRound?.stableCoinAddress &&
											errors.fundingRound?.stableCoinAddress
										}
									/>
								)}
							/>
							<Autocomplete
								options={filteredSupportedTokensByChain ? filteredSupportedTokensByChain : []}
								disableClearable
								selectOnFocus
								clearOnBlur
								onChange={(e, value) =>
									setFieldValue('fundingRound.tokenForSaleAddress', value.address)
								}
								autoHighlight
								sx={{ gridColumn: 'span 4' }}
								getOptionLabel={(option) => `${option.name}: ${option.address}`}
								value={
									filteredSupportedTokensByChain?.find(
										(option) => option.address === values.fundingRound.tokenForSaleAddress
									) || null
								}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant="filled"
										type="text"
										label="Token For Sale Address"
										onBlur={handleBlur}
										name="fundingRound.tokenForSaleAddress"
										error={
											!!touched.fundingRound?.tokenForSaleAddress &&
											!!errors.fundingRound?.tokenForSaleAddress
										}
										helperText={
											touched.fundingRound?.tokenForSaleAddress &&
											errors.fundingRound?.tokenForSaleAddress
										}
									/>
								)}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="White Paper Url"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.whitePaperUrl}
								name="fundingRound.whitePaperUrl"
								error={!!touched.fundingRound?.whitePaperUrl && !!errors.fundingRound?.whitePaperUrl}
								helperText={touched.fundingRound?.whitePaperUrl && errors.fundingRound?.whitePaperUrl}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Open Pager Url"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.onePagerUrl}
								name="fundingRound.onePagerUrl"
								error={!!touched.fundingRound?.onePagerUrl && !!errors.fundingRound?.onePagerUrl}
								helperText={touched.fundingRound?.onePagerUrl && errors.fundingRound?.onePagerUrl}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Motto"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.motto}
								name="fundingRound.motto"
								error={!!touched.fundingRound?.motto && !!errors.fundingRound?.motto}
								helperText={touched.fundingRound?.motto && errors.fundingRound?.motto}
								sx={{ gridColumn: 'span 4' }}
							/>
							<Divider sx={{ gridColumn: 'span 4' }} />
							<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
								Tokenomics
							</Typography>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Price per Token"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.pricePerToken}
								name="fundingRound.pricePerToken"
								error={!!touched.fundingRound?.pricePerToken && !!errors.fundingRound?.pricePerToken}
								helperText={touched.fundingRound?.pricePerToken && errors.fundingRound?.pricePerToken}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Target Amount"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.targetAmount}
								name="fundingRound.targetAmount"
								error={!!touched.fundingRound?.targetAmount && !!errors.fundingRound?.targetAmount}
								helperText={touched.fundingRound?.targetAmount && errors.fundingRound?.targetAmount}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Dollar Target Amount"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.dollarTargetAmount}
								name="fundingRound.dollarTargetAmount"
								error={
									!!touched.fundingRound?.dollarTargetAmount &&
									!!errors.fundingRound?.dollarTargetAmount
								}
								helperText={
									touched.fundingRound?.dollarTargetAmount && errors.fundingRound?.dollarTargetAmount
								}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Max Allocation"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.maxAllocation}
								name="fundingRound.maxAllocation"
								error={!!touched.fundingRound?.maxAllocation && !!errors.fundingRound?.maxAllocation}
								helperText={touched.fundingRound?.maxAllocation && errors.fundingRound?.maxAllocation}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Min Allocation"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.minAllocation}
								name="fundingRound.minAllocation"
								error={!!touched.fundingRound?.minAllocation && !!errors.fundingRound?.minAllocation}
								helperText={touched.fundingRound?.minAllocation && errors.fundingRound?.minAllocation}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Initial Market Cap"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.initialMarketCap}
								name="fundingRound.initialMarketCap"
								error={
									!!touched.fundingRound?.initialMarketCap && !!errors.fundingRound?.initialMarketCap
								}
								helperText={
									touched.fundingRound?.initialMarketCap && errors.fundingRound?.initialMarketCap
								}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Initial Token Supply"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.initialTokenSupply}
								name="fundingRound.initialTokenSupply"
								error={
									!!touched.fundingRound?.initialTokenSupply &&
									!!errors.fundingRound?.initialTokenSupply
								}
								helperText={
									touched.fundingRound?.initialTokenSupply && errors.fundingRound?.initialTokenSupply
								}
								sx={{ gridColumn: 'span 2' }}
							/>
							<Stack spacing={3} sx={{ gridColumn: 'span 2' }}>
								<DesktopDatePicker
									inputFormat="MM/dd/yyyy"
									value={values.fundingRound.signUpStartDate}
									minDate={new Date()}
									onChange={(date) => setFieldValue('fundingRound.signUpStartDate', date)}
									renderInput={(params) => (
										<TextField
											fullWidth
											variant="filled"
											{...params}
											type="date"
											label="Sign Up Start Date"
											onBlur={handleBlur}
											value={values.fundingRound.signUpStartDate}
											name="fundingRound.signUpStartDate"
										/>
									)}
								/>
							</Stack>
							<Stack spacing={3} sx={{ gridColumn: 'span 2' }}>
								<DesktopDatePicker
									inputFormat="MM/dd/yyyy"
									value={values.fundingRound.signUpDeadline}
									minDate={new Date()}
									onChange={(date) => setFieldValue('fundingRound.signUpDeadline', date)}
									renderInput={(params) => (
										<TextField
											fullWidth
											variant="filled"
											{...params}
											type="date"
											value={values.fundingRound.signUpDeadline}
											label="Sign Up Deadline"
											onBlur={handleBlur}
											name="fundingRound.signUpDeadline"
										/>
									)}
								/>
							</Stack>
							<Divider sx={{ gridColumn: 'span 4' }} />
							<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
								Vesting
							</Typography>
							<Stack spacing={3} sx={{ gridColumn: 'span 2' }}>
								<DesktopDatePicker
									inputFormat="MM/dd/yyyy"
									value={values.fundingRound.vestingStartDate}
									minDate={new Date()}
									onChange={(date) => setFieldValue('fundingRound.vestingStartDate', date)}
									renderInput={(params) => (
										<TextField
											fullWidth
											variant="filled"
											{...params}
											type="date"
											label="Vesting Start Date"
											value={values.fundingRound.vestingStartDate}
											onBlur={handleBlur}
											name="fundingRound.vestingStartDate"
										/>
									)}
								/>
							</Stack>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Vesting Duration ( text )"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.vestingDuration}
								name="fundingRound.vestingDuration"
								error={
									!!touched.fundingRound?.vestingDuration && !!errors.fundingRound?.vestingDuration
								}
								helperText={
									touched.fundingRound?.vestingDuration && errors.fundingRound?.vestingDuration
								}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Vesting Period ( number of days )"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.vestingPeriod ?? ''}
								name="fundingRound.vestingPeriod"
								error={!!touched.fundingRound?.vestingPeriod && !!errors.fundingRound?.vestingPeriod}
								helperText={touched.fundingRound?.vestingPeriod && errors.fundingRound?.vestingPeriod}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Start Redeem Percentage ( % )"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fundingRound.startRedeemPercentage ?? ''}
								name="fundingRound.startRedeemPercentage"
								error={
									!!touched.fundingRound?.startRedeemPercentage &&
									!!errors.fundingRound?.startRedeemPercentage
								}
								helperText={
									touched.fundingRound?.startRedeemPercentage &&
									errors.fundingRound?.startRedeemPercentage
								}
								sx={{ gridColumn: 'span 2' }}
							/>
							<Divider sx={{ gridColumn: 'span 4' }} />
							<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
								Overview
							</Typography>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Highlights"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.overview?.highlights}
								name="overview.highlights"
								error={!!touched.overview?.highlights && !!errors.overview?.highlights}
								helperText={touched.overview?.highlights && errors.overview?.highlights}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Product"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.overview?.product}
								name="overview.product"
								error={!!touched.overview?.product && !!errors.overview?.product}
								helperText={touched.overview?.product && errors.overview?.product}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Problem"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.overview?.problem}
								name="overview.problem"
								error={!!touched.overview?.problem && !!errors.overview?.problem}
								helperText={touched.overview?.problem && errors.overview?.problem}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Solution"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.overview?.solution}
								name="overview.solution"
								error={!!touched.overview?.solution && !!errors.overview?.solution}
								helperText={touched.overview?.solution && errors.overview?.solution}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Business Model"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.overview?.businessModel}
								name="overview.businessModel"
								error={!!touched.overview?.businessModel && !!errors.overview?.businessModel}
								helperText={touched.overview?.businessModel && errors.overview?.businessModel}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Market"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.overview?.market}
								name="overview.market"
								error={!!touched.overview?.market && !!errors.overview?.market}
								helperText={touched.overview?.market && errors.overview?.market}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Investors"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.overview?.investors}
								name="overview.investors"
								error={!!touched.overview?.investors && !!errors.overview?.investors}
								helperText={touched.overview?.investors && errors.overview?.investors}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color="secondary" variant="contained">
								Create New Funding Round
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
}
