import { Configuration } from '../config/Config';
import { BlogPost } from '../redux/features/blogPostSlice';

import { BlogPostService } from './url';

export const blogPostFetches = {
	listFetch: async () => {
		const response = await fetch(`${Configuration.baseUrl}${BlogPostService.LIST_BLOG_POSTS}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},

	addFetch: async (formData: FormData) => {
		const response = await fetch(`${Configuration.baseUrl}${BlogPostService.CREATE_BLOG_POST}`, {
			method: 'POST',
			headers: {
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: formData,
		});
		return await response;
	},

	updatePhotoFetch: async (formData: FormData) => {
		const response = await fetch(`${Configuration.baseUrl}${BlogPostService.UPDATE_BLOG_POST}`, {
			method: 'POST',
			headers: {
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: formData,
		});
		return await response;
	},

	updateFetch: async (blogPost: BlogPost) => {
		const response = await fetch(`${Configuration.baseUrl}${BlogPostService.UPDATE_BLOG_POST}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				...blogPost,
			}),
		});
		return await response;
	},

	removeFetch: async (id: number, photoUrl: string) => {
		const response = await fetch(`${Configuration.baseUrl}${BlogPostService.REMOVE_BLOG_POST}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				id,
				photoUrl,
			}),
		});
		return await response;
	},

	getFetch: async (blogPostId?: string) => {
		let getUrl = `${Configuration.baseUrl}${BlogPostService.GET_BLOG_POST}`;

		if (blogPostId !== undefined) {
			getUrl = `${getUrl}/${blogPostId}`;
		}

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return await response;
	},
};
