import { Box, Button, TextField, useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { router } from '../../Router';
import Header from '../../components/global/Header';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { AdminUser, add } from '../../redux/features/adminUserSlice';
import { logout } from '../../redux/features/configSlice';
import { adminUserFetches } from '../../service';

export function AddAdminUserPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const dispatch = useDispatch();

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const currentAdminUser = useSelector((state: RootState) => state.currentAdminUserReducer);
	const adminUserInitialValues: AdminUser = { email: '', password: '', adminUserRole: 'admin' };

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	const handleAddFormSubmit = async (values: AdminUser) => {
		const adminUserToAdd = {
			...values,
		} as AdminUser;
		const addResponse = await adminUserFetches.addFetch(adminUserToAdd);

		if (addResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await addResponse.json();

		if (data.status === true) {
			const adminUserToAddToStore = data.data;
			dispatch(add(adminUserToAddToStore));

			history.push(router.listAdminUsers().$);
		} else {
			setServerErrorMessage(data.error.value);
		}
	};

	const adminUserValidationSchema = yup.object().shape({
		email: yup.string().trim().email('invalid email').required('required'),
		password: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		adminUserRole: yup.string().required('requied'),
	});

	return (
		<Box m="20px">
			<Header title="ADD ADMIN USER" subtitle="Create a New Admin User Profile" />
			<Formik
				onSubmit={handleAddFormSubmit}
				initialValues={adminUserInitialValues}
				validationSchema={adminUserValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Email"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email}
								name="email"
								error={!!touched.email && !!errors.email}
								helperText={touched.email && errors.email}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="password"
								label="Password"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.password}
								name="password"
								error={!!touched.password && !!errors.password}
								helperText={touched.password && errors.password}
								sx={{ gridColumn: 'span 4' }}
							/>
							{currentAdminUser.role === 'owner' && (
								<TextField
									fullWidth
									variant="filled"
									type="text"
									label="User Role"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.adminUserRole}
									name="adminUserRole"
									error={!!touched.adminUserRole && !!errors.adminUserRole}
									helperText={touched.adminUserRole && errors.adminUserRole}
									sx={{ gridColumn: 'span 2' }}
									inputProps={{ readOnly: true }}
								/>
							)}
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color="secondary" variant="contained">
								Create New Admin User
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
}
