import { combineReducers } from '@reduxjs/toolkit';
import localforage from 'localforage';
import { persistReducer } from 'redux-persist';
import adminUserReducer from './features/adminUserSlice';
import blogPostReducer from './features/blogPostSlice';
import chainReducer from './features/chainSlice';
import configReducer from './features/configSlice';
import currentAdminUserReducer from './features/currentAdminUserSlice';
import featuredProjectReducer from './features/featuredProjectSlice';
import fundingRoundReducer from './features/fundingRoundSlice';
import projectReducer from './features/projectSlice';
import supportedTokenReducer from './features/supportedTokenSlice';

const combinedReducer = combineReducers({
	featuredProjectReducer,
	projectReducer,
	currentAdminUserReducer,
	configReducer,
	adminUserReducer,
	fundingRoundReducer,
	chainReducer,
	supportedTokenReducer,
	blogPostReducer,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === 'config/logout') {
		state = undefined;
	}
	return combinedReducer(state, action);
};

const persistConfig = {
	key: 'root',
	version: 1,
	storage: localforage, //or localstorage
	blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
