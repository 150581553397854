import { Box, Button, TextField, useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import Header from '../../components/global/Header';
import { useDispatch } from '../../hooks/hooks';
import { logout } from '../../redux/features/configSlice';
import { add, Project } from '../../redux/features/projectSlice';
import { router } from '../../Router';
import { projectFetches } from '../../service';

export function AddProjectPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const dispatch = useDispatch();
	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const projectInitialValues: Project = {
		firstName: '',
		lastName: '',
		projectName: '',
		email: '',
		website: '',
		twitter: '',
		personalTelegramHandle: '',
		projectTelegramGroup: '',
		onePager: '',
		marketingDeck: '',
		tokenomics: '',
		evidenceOfDevelopment: '',
		collectiveSource: '',
		description: '',
		isVisible: false,
	};

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	const handleAddFormSubmit = async (values: Project) => {
		const projectToAdd = {
			...values,
			email: values.email.trim(),
			website: values.website.trim(),
		} as Project;
		const addResponse = await projectFetches.addFetch(projectToAdd);

		if (addResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await addResponse.json();

		if (data.status === true) {
			projectToAdd.id = data.data.id;
			dispatch(add(projectToAdd));
			history.push(router.listProjects().$);
		} else {
			setServerErrorMessage(data.error.value);
		}
	};

	const projectValidationSchema = yup.object().shape({
		firstName: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		lastName: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		projectName: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		email: yup.string().trim().email('invalid email').required('required'),
		website: yup.string().trim().url('invalid url').required('required'),
		twitter: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		personalTelegramHandle: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		projectTelegramGroup: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		onePager: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		marketingDeck: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		tokenomics: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		evidenceOfDevelopment: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		collectiveSource: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		description: yup
			.string()
			.min(4, 'must be at least 4 characters long')
			.max(255, 'maximum characters 255')
			.required('required'),
		isVisible: yup.boolean().required('required'),
	});

	return (
		<Box m="20px">
			<Header title="ADD PROJECT" subtitle="Create a New Project" />
			<Formik
				onSubmit={handleAddFormSubmit}
				initialValues={projectInitialValues}
				validationSchema={projectValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="First Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.firstName}
								name="firstName"
								error={!!touched.firstName && !!errors.firstName}
								helperText={touched.firstName && errors.firstName}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Last Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.lastName}
								name="lastName"
								error={!!touched.lastName && !!errors.lastName}
								helperText={touched.lastName && errors.lastName}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Project Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.projectName}
								name="projectName"
								error={!!touched.projectName && !!errors.projectName}
								helperText={touched.projectName && errors.projectName}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Twitter"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.twitter}
								name="twitter"
								error={!!touched.twitter && !!errors.twitter}
								helperText={touched.twitter && errors.twitter}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Email"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email}
								name="email"
								error={!!touched.email && !!errors.email}
								helperText={touched.email && errors.email}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Website"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.website}
								name="website"
								error={!!touched.website && !!errors.website}
								helperText={touched.website && errors.website}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Personal Telegram Handle"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.personalTelegramHandle}
								name="personalTelegramHandle"
								error={!!touched.personalTelegramHandle && !!errors.personalTelegramHandle}
								helperText={touched.personalTelegramHandle && errors.personalTelegramHandle}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Project Telegram Group"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.projectTelegramGroup}
								name="projectTelegramGroup"
								error={!!touched.projectTelegramGroup && !!errors.projectTelegramGroup}
								helperText={touched.projectTelegramGroup && errors.projectTelegramGroup}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Onepager"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.onePager}
								name="onePager"
								error={!!touched.onePager && !!errors.onePager}
								helperText={touched.onePager && errors.onePager}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Marketing Deck"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.marketingDeck}
								name="marketingDeck"
								error={!!touched.marketingDeck && !!errors.marketingDeck}
								helperText={touched.marketingDeck && errors.marketingDeck}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Tokenomics"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.tokenomics}
								name="tokenomics"
								error={!!touched.tokenomics && !!errors.tokenomics}
								helperText={touched.tokenomics && errors.tokenomics}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Evidence of Development"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.evidenceOfDevelopment}
								name="evidenceOfDevelopment"
								error={!!touched.evidenceOfDevelopment && !!errors.evidenceOfDevelopment}
								helperText={touched.evidenceOfDevelopment && errors.evidenceOfDevelopment}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Collective Source"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.collectiveSource}
								name="collectiveSource"
								error={!!touched.collectiveSource && !!errors.collectiveSource}
								helperText={touched.collectiveSource && errors.collectiveSource}
								sx={{ gridColumn: 'span 4' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Description"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.description}
								name="description"
								error={!!touched.description && !!errors.description}
								helperText={touched.description && errors.description}
								sx={{ gridColumn: 'span 4' }}
							/>
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color="secondary" variant="contained">
								Create New Project
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
}
