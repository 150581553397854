import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardMedia,
	FormControlLabel,
	Switch,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from 'react-typesafe-routes';
import * as yup from 'yup';
import Header from '../../components/global/Header';
import { Loader } from '../../components/Loader';
import { Configuration } from '../../config/Config';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { BlogPost, update, updatePhoto } from '../../redux/features/blogPostSlice';
import { logout } from '../../redux/features/configSlice';

import { router } from '../../Router';
import { blogPostFetches } from '../../service';
import { tokens } from '../../theme';

export function GetBlogPostPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const dispatch = useDispatch();

	const { id } = useRouteParams(router.getBlogPost);

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const selectedBlogPost = useSelector((state: RootState) => state.blogPostReducer.selectedBlogPost);

	const [checkFeatured, setCheckFeatured] = React.useState({ checked: selectedBlogPost?.isFeatured });
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [selectedFile, setSelectedFile] = React.useState<{ photoFile: File }>();

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	const handleUpdateFormSubmit = async (values: BlogPost) => {
		const blogPostToUpdate = {
			...values,
			id: id,
			photoUrl: selectedBlogPost?.photoUrl,
			isFeatured: checkFeatured.checked,
		} as BlogPost;
		const updateResponse = await blogPostFetches.updateFetch(blogPostToUpdate);

		if (updateResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await updateResponse.json();

		if (data.status === true) {
			dispatch(update(blogPostToUpdate));
			history.push(router.listBlogPosts().$);
			// history.push(router.getProject({ id: id }).$);
		} else {
			setServerErrorMessage(data.error.value);
		}
	};

	const handleUploadPhoto = async () => {
		const formData = new FormData();
		formData.append('file', selectedFile!.photoFile as File);

		if (selectedBlogPost?.id) {
			formData.append('id', selectedBlogPost.id.toString());
		}

		setIsLoading(true);
		const updatePhotoResponse = await blogPostFetches.updatePhotoFetch(formData);

		if (updatePhotoResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await updatePhotoResponse.json();

		if (data.status === true) {
			const blogPostPhotoUpdate = { id: id, photoUrl: data.data } as BlogPost;
			dispatch(updatePhoto(blogPostPhotoUpdate));

			setIsLoading(false);
			if (isLoading === false) {
				//history.push(router.listFeaturedProjects().$);
				history.push(router.getBlogPost({ id: id }).$);
				//window.location.reload();
			}
		} else {
			setServerErrorMessage(data.error.value);
		}
	};

	const blogPostValidationSchema = yup.object().shape({
		title: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		description: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		category: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		blogLink: yup.string().url('Must be a valid URL').required('required'),
		isFeatured: yup.boolean().optional(),
	});

	const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckFeatured({ ...checkFeatured, [event.target.name]: event.target.checked });
	};

	return selectedBlogPost ? (
		<Box m="20px">
			{isLoading ? <Loader open={isLoading} /> : null}
			<Box sx={{ gridColumn: 'span 4' }} display="flex" justifyContent="flex-start">
				<Header title="BLOG POST INFO" subtitle="Blog Post data / Update Blog Post" />
				<FormControlLabel
					control={
						<Switch
							checked={checkFeatured.checked}
							onChange={handleCheckChange}
							name="checked"
							color="success"
						/>
					}
					label="Featured"
				/>
			</Box>
			<Formik
				onSubmit={handleUpdateFormSubmit}
				initialValues={selectedBlogPost!}
				validationSchema={blogPostValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Title"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.title}
								name="title"
								error={!!touched.title && !!errors.title}
								helperText={touched.title && errors.title}
								sx={{ gridColumn: 'span 2' }}
							/>
							<Autocomplete
								options={Configuration.blogCategory}
								disableClearable
								selectOnFocus
								clearOnBlur
								onChange={(e, value) => setFieldValue('category', value)}
								autoHighlight
								sx={{ gridColumn: 'span 2' }}
								getOptionLabel={(option) => option}
								value={values.category}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant="filled"
										type="text"
										onBlur={handleBlur}
										label="Category"
										name="category"
										error={!!touched.category && !!errors.category}
										helperText={touched.category && errors.category}
									/>
								)}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Description"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.description}
								name="description"
								error={!!touched.description && !!errors.description}
								helperText={touched.description && errors.description}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Blog Link"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.blogLink}
								name="blogLink"
								error={!!touched.blogLink && !!errors.blogLink}
								helperText={touched.blogLink && errors.blogLink}
								sx={{ gridColumn: 'span 4' }}
							/>
							<Box width="370px">
								<Box mb="12px">
									<input
										type="file"
										style={{ color: colors.redAccent[500] }}
										onChange={(e) => setSelectedFile({ photoFile: e.target.files![0] })}
									/>
									<Button
										variant="outlined"
										color="secondary"
										onClick={() => handleUploadPhoto()}
										sx={{ color: colors.grey[100] }}
										disabled={selectedFile?.photoFile ? false : true}
									>
										Upload Photo
									</Button>
								</Box>
								<Box>
									{selectedBlogPost.photoUrl !== null &&
									selectedBlogPost.photoUrl !== '' &&
									selectedBlogPost.photoUrl !== undefined ? (
										<Card sx={{ maxWidth: '345' }}>
											<CardMedia
												component="img"
												image={`${selectedBlogPost.photoUrl}`}
												height="200"
												title="Media"
											/>
										</Card>
									) : null}
								</Box>
							</Box>
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color="secondary" variant="contained">
								Update Blog Post
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	) : null;
}
