import { Box, Button, Card, CardMedia, TextField, useMediaQuery, useTheme } from '@mui/material';
import { ethers } from 'ethers';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from 'react-typesafe-routes';
import * as yup from 'yup';
import Header from '../../components/global/Header';
import { Loader } from '../../components/Loader';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { logout } from '../../redux/features/configSlice';
import { SupportedToken, update, updateLogoPhoto } from '../../redux/features/supportedTokenSlice';
import { router } from '../../Router';
import { supportedTokenFetches } from '../../service';
import { tokens } from '../../theme';

export function GetSupportedTokenPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const dispatch = useDispatch();

	const { id } = useRouteParams(router.getSupportedToken);

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const selectedSupportedToken = useSelector(
		(state: RootState) => state.supportedTokenReducer.selectedSupportedToken
	);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [selectedFile, setSelectedFile] = React.useState<{ logoFile: File }>();

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	const handleUpdateFormSubmit = async (values: SupportedToken) => {
		const supportedTokenToUpdate = {
			...values,
			id: id,
			logoUrl: selectedSupportedToken?.logoUrl,
		} as SupportedToken;
		const updateResponse = await supportedTokenFetches.updateFetch(supportedTokenToUpdate);

		if (updateResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await updateResponse.json();

		if (data.status === true) {
			dispatch(update(supportedTokenToUpdate));
			history.push(router.listSupportedTokens().$);
			// history.push(router.getProject({ id: id }).$);
		} else {
			setServerErrorMessage(data.error.value);
		}
	};

	const handleUploadPhoto = async () => {
		const formData = new FormData();
		formData.append('file', selectedFile!.logoFile as File);

		if (selectedSupportedToken?.id) {
			formData.append('id', selectedSupportedToken.id.toString());
		}

		setIsLoading(true);
		const updatePhotoResponse = await supportedTokenFetches.updatePhotoFetch(formData);

		if (updatePhotoResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await updatePhotoResponse.json();

		if (data.status === true) {
			const supportedTokenPhotoUpdate = { id: id, logoUrl: data.data } as SupportedToken;
			dispatch(updateLogoPhoto(supportedTokenPhotoUpdate));

			setIsLoading(false);
			if (isLoading === false) {
				//history.push(router.listFeaturedProjects().$);
				history.push(router.getSupportedToken({ id: id }).$);
				//window.location.reload();
			}
		} else {
			setServerErrorMessage(data.error.value);
		}
	};

	const supportedTokenValidationSchema = yup.object().shape({
		name: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		chainId: yup.string().required('required').nullable(),
		address: yup
			.string()
			.required('required')
			.test({
				name: 'addressError',
				message: 'invalid address',
				test: (value) => ethers.utils.isAddress(value!),
			}),
		symbol: yup.string().min(4, 'must be at least 4 characters long').required('required'),
	});

	return selectedSupportedToken ? (
		<Box m="20px">
			{isLoading ? <Loader open={isLoading} /> : null}
			<Header title="SUPPORTED TOKEN INFO" subtitle="Supported Token data / Update Supported Token" />
			<Formik
				onSubmit={handleUpdateFormSubmit}
				initialValues={selectedSupportedToken!}
				validationSchema={supportedTokenValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.name}
								name="name"
								error={!!touched.name && !!errors.name}
								helperText={touched.name && errors.name}
								sx={{ gridColumn: 'span 2' }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Chain"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.chain!.name}
								name="chainId"
								error={!!touched.chainId && !!errors.chainId}
								helperText={touched.chainId && errors.chainId}
								sx={{ gridColumn: 'span 2' }}
								inputProps={{ readOnly: true }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Address"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.address}
								name="address"
								error={!!touched.address && !!errors.address}
								helperText={touched.address && errors.address}
								sx={{ gridColumn: 'span 4' }}
								inputProps={{ readOnly: true }}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Symbol"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.symbol}
								name="symbol"
								error={!!touched.symbol && !!errors.symbol}
								helperText={touched.symbol && errors.symbol}
								sx={{ gridColumn: 'span 4' }}
							/>
							<Box>
								<Box mb="12px">
									<input
										type="file"
										style={{ color: colors.redAccent[500] }}
										onChange={(e) => setSelectedFile({ logoFile: e.target.files![0] })}
									/>
									<Button
										variant="outlined"
										color="secondary"
										onClick={() => handleUploadPhoto()}
										sx={{ color: colors.grey[100] }}
										disabled={selectedFile?.logoFile ? false : true}
									>
										Upload Logo
									</Button>
								</Box>
								<Box>
									{selectedSupportedToken.logoUrl !== null &&
									selectedSupportedToken.logoUrl !== '' &&
									selectedSupportedToken.logoUrl !== undefined ? (
										<Card sx={{ maxWidth: '345' }}>
											<CardMedia
												component="img"
												image={`${selectedSupportedToken.logoUrl}`}
												height="200"
												title="Media"
											/>
										</Card>
									) : null}
								</Box>
							</Box>
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color="secondary" variant="contained">
								Update Supported Token
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	) : null;
}
