// prettier-ignore
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Edit';
import { Button, IconButton, useTheme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { router } from '../Router';
import { useDispatch, useSelector } from '../hooks/hooks';
import { RootState } from '../redux/configureStore';
import { logout } from '../redux/features/configSlice';
import { setCurrentFundingRound, updateCurrentFundingRound } from '../redux/features/featuredProjectSlice';
import { FundingRound, deleteFundingRound, getFundingRoundData } from '../redux/features/fundingRoundSlice';
import { featuredProjectFetches, fundingRoundFetches } from '../service';
import { tokens } from '../theme';

export function FundingRoundsTable() {
	const history = useHistory();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const dispatch = useDispatch();

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	// get funding rounds list and selected featured project from redux store
	const fundingRoundsList = useSelector((state: RootState) => state.fundingRoundReducer.fundingRounds);
	const selectedFeaturedProject = useSelector(
		(state: RootState) => state.featuredProjectReducer.selectedFeaturedProject
	);

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	// get funding rounds only for selected featured project
	const listByFeaturedProject = fundingRoundsList.filter(
		(item) => item.featuredProjectId === selectedFeaturedProject?.id
	);

	// set, edit and delete button creation, used in table to view
	const ActionButtonsRenderer = (props: any) => (
		<strong>
			<Button
				aria-label="Set"
				variant="contained"
				sx={{
					bgcolor: colors.blueAccent[600],
					':hover': { bgcolor: colors.blueAccent[700] },
					':disabled': { color: colors.grey[800], bgcolor: colors.grey[300] },
				}}
				size="small"
				onClick={async (e) => {
					if (props.value) {
						const newCurrentFundingRound = props.node.data as FundingRound;
						if (
							newCurrentFundingRound.signUpDeadline === null ||
							newCurrentFundingRound.signUpDeadline === undefined ||
							newCurrentFundingRound.signUpStartDate === null ||
							newCurrentFundingRound.signUpStartDate === undefined
						) {
							alert(`Sign Up Deadline or Sign Up Start Date missing!`);
							return;
						}
						// set the current funding round for the selected featured project
						const setCurrentReponse = await featuredProjectFetches.setCurrentFundingRoundFetch(
							selectedFeaturedProject?.id as number,
							props.value as number
						);

						if (setCurrentReponse.status === 403) {
							dispatch(logout());
							return;
						}
						const data = await setCurrentReponse.json();
						console.log(data);

						if (data.status === true) {
							console.log(newCurrentFundingRound);
							// set current funding round data to redux store
							const dataToSend = {
								featuredProjectId: selectedFeaturedProject?.id as number,
								fundingRoundId: props.value as number,
								newCurrentFundingRound: newCurrentFundingRound,
							} as any;
							await dispatch(setCurrentFundingRound(dataToSend));
						} else {
							setServerErrorMessage(`${data.error}`);
						}
					}
				}}
				// disabled={
				// 	selectedFeaturedProject?.currentFundingRoundId &&
				// 	selectedFeaturedProject.currentFundingRoundId === props.value
				// 		? true
				// 		: false
				// }
			>
				Set Current
			</Button>
			<IconButton
				aria-label="Edit"
				sx={{ color: 'green', ':hover': { bgcolor: '#dcdefc' } }}
				onClick={async (e) => {
					// get funding round data and set it as selected
					const fundingRoundData = await dispatch(getFundingRoundData(props.value));

					// update selectedFeaturedProject current Funding Round
					if (selectedFeaturedProject?.currentFundingRoundId === props.value) {
						console.log('round is curent funding round');
						dispatch(updateCurrentFundingRound(fundingRoundData));
					}

					// go to selected funding round info page
					history.push(
						router.getFundingRound({
							featuredProjectId: selectedFeaturedProject?.id,
							fundingRoundId: props.value,
						}).$
					);
				}}
			>
				<UpdateIcon />
			</IconButton>
			<IconButton
				aria-label="Delete"
				sx={{ color: 'red', ':hover': { bgcolor: '#dcdefc' } }}
				onClick={async (e) => {
					if (props.value) {
						// execute delete request if id exists
						const removeResponse = await fundingRoundFetches.removeFetch(props.value as number);

						if (removeResponse.status === 403) {
							dispatch(logout());
							return;
						}
						const data = await removeResponse.json();

						if (data.status === true) {
							// delete data from redux store
							dispatch(deleteFundingRound(props.value as number));
						} else {
							setServerErrorMessage(`${data.error}`);
						}
					}
				}}
			>
				<DeleteIcon />
			</IconButton>
		</strong>
	);

	const columns = [
		{ headerName: 'Name', field: 'name', sortable: true, filter: true, floatingFilter: true },
		{ headerName: 'Target Amount', field: 'targetAmount', sortable: true, filter: true, floatingFilter: true },
		{
			headerName: 'Funding Chain',
			field: 'fundingChain.name',
			sortable: true,
			filter: true,
			floatingFilter: true,
		},
		{
			headerName: 'Vesting Chain',
			field: 'vestingChain.name',
			sortable: true,
			filter: true,
			floatingFilter: true,
		},
		{
			headerName: 'Sign Up Deadline',
			field: 'signUpDeadline',
			sortable: true,
			filter: true,
			floatingFilter: true,
			valueGetter: (params: any) => {
				// setting what to see if featured project has current funding round or not
				if (params.data.signUpDeadline) {
					return new Date(params.data.signUpDeadline).toLocaleDateString();
				}
			},
		},
		{
			headerName: 'Status',
			field: 'status',
			sortable: true,
			filter: true,
			floatingFilter: true,
			width: 250,
		},
		{
			headerName: 'Is current round',
			sortable: true,
			filter: true,
			floatingFilter: true,
			width: 150,
			cellStyle: (params: any) => {
				if (params.value === true) {
					return { color: 'green' };
				} else {
					return { color: 'red' };
				}
			},
			valueGetter: (params: any) => {
				// show if funding round is the current funding round or not
				if (
					selectedFeaturedProject?.currentFundingRoundId &&
					selectedFeaturedProject.currentFundingRoundId === params.data.id
				) {
					return true;
				} else {
					return false;
				}
			},
		},
		{
			headerName: 'Set / Edit / Delete',
			cellStyle: { border: 'none' },
			field: 'id',
			width: 250,
			cellRenderer: 'actionRenderer',
		},
	];

	return (
		<AgGridReact
			columnDefs={columns}
			rowData={listByFeaturedProject}
			rowSelection="single"
			pagination={true}
			paginationAutoPageSize={true}
			frameworkComponents={{
				actionRenderer: ActionButtonsRenderer,
			}}
		/>
	);
}
