import { intParser, Router } from 'react-typesafe-routes';
import {
	AddAdminUserPage,
	AddBlogPostPage,
	AddFeaturedProjectPage,
	AddFundingRoundPage,
	AddProjectPage,
	AddSupportedTokenPage,
	CompanyWalletsPage,
	DashboardPage,
	GetAdminUserPage,
	GetBlogPostPage,
	GetFeaturedProjectPage,
	GetFundingRoundPage,
	GetProjectPage,
	GetSupportedTokenPage,
	GetSupportMessagePage,
	ListAdminUsersPage,
	ListBlogPostsPage,
	ListFeaturedProjectsPage,
	ListFundingRoundsPage,
	ListProjectsPage,
	ListSupportedTokensPage,
	ListSupportMessagesPage,
	Login,
	MarketplacePage,
	TreasuryWalletPage,
} from './pages/index';

// Read more about writing a middleware or add query parameter etc.
// https://github.com/innFactory/react-typesafe-routes

export const router = Router((route) => ({
	home: route('/', {
		component: DashboardPage,
	}),

	login: route('login', {
		component: Login,
	}),

	treasuryWallet: route('treasury-wallet', {
		component: TreasuryWalletPage,
	}),

	companyWallets: route('company-wallets', {
		component: CompanyWalletsPage,
	}),

	marketplace: route('marketplace', {
		component: MarketplacePage,
	}),

	listProjects: route('list-projects', {
		component: ListProjectsPage,
	}),
	addProject: route('add-project', {
		component: AddProjectPage,
	}),
	getProject: route('get-project/:id?', {
		component: GetProjectPage,
		params: {
			id: intParser,
		},
	}),

	listFeaturedProjects: route('list-featured-projects', {
		component: ListFeaturedProjectsPage,
	}),
	addFeaturedProject: route('add-featured-project', {
		component: AddFeaturedProjectPage,
	}),
	getFeaturedProject: route('get-featured-project/:id?', {
		component: GetFeaturedProjectPage,
		params: {
			id: intParser,
		},
	}),

	listAdminUsers: route('list-admin-users', {
		component: ListAdminUsersPage,
	}),
	addAdminUser: route('add-admin-user', {
		component: AddAdminUserPage,
	}),
	getAdminUser: route('get-admin-user/:id?', {
		component: GetAdminUserPage,
		params: {
			id: intParser,
		},
	}),

	listFundingRounds: route('get-featured-project/:featuredProjectId?/list-funding-rounds', {
		component: ListFundingRoundsPage,
		params: {
			featuredProjectId: intParser,
		},
	}),
	addFundingRound: route('get-featured-project/:featuredProjectId?/add-funding-round', {
		component: AddFundingRoundPage,
		params: {
			featuredProjectId: intParser,
		},
	}),
	getFundingRound: route('get-featured-project/:featuredProjectId?/get-funding-round/:fundingRoundId?', {
		component: GetFundingRoundPage,
		params: {
			featuredProjectId: intParser,
			fundingRoundId: intParser,
		},
	}),

	listSupportedTokens: route('list-supported-tokens', {
		component: ListSupportedTokensPage,
	}),
	addSupportedToken: route('add-supported-token', {
		component: AddSupportedTokenPage,
	}),
	getSupportedToken: route('get-supported-token/:id?', {
		component: GetSupportedTokenPage,
		params: {
			id: intParser,
		},
	}),

	listBlogPosts: route('list-blog-posts', {
		component: ListBlogPostsPage,
	}),
	addBlogPost: route('add-blog-post', {
		component: AddBlogPostPage,
	}),
	getBlogPost: route('get-blog-post/:id?', {
		component: GetBlogPostPage,
		params: {
			id: intParser,
		},
	}),

	listSupportMessagess: route('list-support-messages', {
		component: ListSupportMessagesPage,
	}),
	getSupportMessage: route('get-support-message/:id?', {
		component: GetSupportMessagePage,
		params: {
			id: intParser,
		},
	}),
}));
