import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { blogPostFetches } from '../../service';
import { AppDispatch } from '../configureStore';
import { logout } from './configSlice';

export interface BlogPost {
	id?: number;
	title: string;
	description: string;
	category: string;
	isFeatured?: boolean;
	blogLink: string;
	photoUrl?: string;
}

export interface BlogPostState {
	blogPosts: BlogPost[];
	selectedBlogPost: BlogPost | null;
}

const blogPostInitialState: BlogPostState = {
	blogPosts: [],
	selectedBlogPost: null,
};

export const blogPostSlice = createSlice({
	name: 'blogPost',
	initialState: blogPostInitialState,
	reducers: {
		add: (state, action: PayloadAction<BlogPost>) => {
			state.blogPosts.push(action.payload);
		},
		get: (state, action: PayloadAction<BlogPost[]>) => {
			state.blogPosts = action.payload;
		},
		update: (state, action: PayloadAction<BlogPost>) => {
			for (const index in state.blogPosts) {
				if (state.blogPosts[index].id === action.payload.id && state.selectedBlogPost) {
					state.blogPosts[index] = action.payload;
					state.selectedBlogPost = action.payload;
				}
			}
		},
		deleteBlogPost: (state, action: PayloadAction<number>) => {
			state.blogPosts = state.blogPosts.filter((t) => t.id !== action.payload);
		},
		selectBlogPost: (state, action: PayloadAction<BlogPost>) => {
			state.selectedBlogPost = action.payload;
		},
		updatePhoto: (state, action: PayloadAction<BlogPost>) => {
			for (const index in state.blogPosts) {
				if (state.blogPosts[index].id === action.payload.id && state.selectedBlogPost) {
					state.blogPosts[index].photoUrl = action.payload.photoUrl;
					state.selectedBlogPost.photoUrl = action.payload.photoUrl;
				}
			}
		},
	},
});

export const initBlogPosts = () => async (dispatch: AppDispatch) => {
	const listReponse = await blogPostFetches.listFetch();

	if (listReponse.status === 403) {
		await dispatch(logout());
		return;
	}
	const data = await listReponse.json();

	if (data.status === true) {
		await dispatch(get(data.data));
	}
};

// Action creators are generated for each case reducer function
export const { add, get, update, deleteBlogPost, selectBlogPost, updatePhoto } = blogPostSlice.actions;

export default blogPostSlice.reducer;
