import FeaturedProjectIcon from '@mui/icons-material/AutoAwesomeMotion';
import ProxyWalletIcon from '@mui/icons-material/ContactMail';
import EmailWalletIcon from '@mui/icons-material/Email';
import FundingRoundIcon from '@mui/icons-material/Grading';
import MoneyIcon from '@mui/icons-material/MonetizationOn';
import MetamaskWalletIcon from '@mui/icons-material/Wallet';
import { Box, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { BarChartStatisticsMonths } from '../components';
import Header from '../components/global/Header';
import { StatBox } from '../components/StatBox';
import { useDispatch } from '../hooks/hooks';
import { logout, Statistic } from '../redux/features/configSlice';
import { router } from '../Router';
import { statisticsFetches } from '../service';
import { tokens } from '../theme';

export function DashboardPage() {
	const history = useHistory();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const dispatch = useDispatch();

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const [statistics, setStatistics] = React.useState<{
		totalStatistic: Statistic | null;
		monthsStatistics: Statistic[] | null;
		monthsUpriseStatistics: Statistic[] | null;
	}>();

	const initStatistics = async () => {
		const totalResponse = await statisticsFetches.getTotalFetch();
		const monthsResponse = await statisticsFetches.listForMonthsFetch();
		const monthsUpriseResponse = await statisticsFetches.listStatisticMonthsUpriseFetch();

		if (totalResponse.status === 403 || monthsResponse.status === 403 || monthsUpriseResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const totalData = await totalResponse.json();
		const monthsData = await monthsResponse.json();
		const monthsUpriseData = await monthsUpriseResponse.json();

		if (totalData.status === true && monthsData.status === true && monthsUpriseData.status === true) {
			setStatistics({
				totalStatistic: totalData.data,
				monthsStatistics: monthsData.data,
				monthsUpriseStatistics: monthsUpriseData.data,
			});
		}
		if (!totalData.status === true || !monthsData.status === true || !monthsUpriseData.status === true) {
			setServerErrorMessage(
				`- ${monthsData.error?.value} - ${totalData.error?.value} - ${monthsUpriseData.error?.value}-`
			);
		}
	};

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			initStatistics();
		}
	}, [history]);

	return (
		<Box m="20px">
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Header title="DASHBOARD" subtitle="Welcome to Collective-Pad dashboard" />
				<Typography
					variant="h6"
					sx={{ color: colors.redAccent[500], alignSelf: 'flex-start', width: '40%', ml: '10px', mt: '7px' }}
				>
					{serverErrorMessage ? `-There is a server error-${serverErrorMessage}` : null}
				</Typography>
			</Box>
			<Box
				mt="25px"
				p="0 30px"
				display="flex "
				justifyContent="space-between"
				alignItems="center"
				bgcolor={colors.primary[400]}
				height="50px"
			>
				<Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
					Statistics Total
				</Typography>
			</Box>
			<Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="20px" mt="25px">
				<Box
					gridColumn="span 4"
					bgcolor={colors.primary[400]}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<StatBox
						title={`${statistics?.totalStatistic?.metamaskWalletsCreated}`}
						subtitle="Metamask Wallets Accounts"
						progress="0.75"
						increase="+14%"
						icon={<MetamaskWalletIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
					/>
				</Box>
				<Box
					gridColumn="span 4"
					bgcolor={colors.primary[400]}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<StatBox
						title={`${statistics?.totalStatistic?.proxyWalletsCreated}`}
						subtitle="Proxy Wallets Accounts"
						progress="0.50"
						increase="+21%"
						icon={<ProxyWalletIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
					/>
				</Box>
				<Box
					gridColumn="span 4"
					bgcolor={colors.primary[400]}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<StatBox
						title={`${statistics?.totalStatistic?.emailWalletsCreated}`}
						subtitle="Email Accounts"
						progress="0.50"
						increase="+21%"
						icon={<EmailWalletIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
					/>
				</Box>
				<Box
					gridColumn="span 4"
					bgcolor={colors.primary[400]}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<StatBox
						title={`${statistics?.totalStatistic?.featuredProjectsAdded}`}
						subtitle="Featured Projects Created"
						progress="0.30"
						increase="+5%"
						icon={<FeaturedProjectIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
					/>
				</Box>
				<Box
					gridColumn="span 4"
					bgcolor={colors.primary[400]}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<StatBox
						title={`${statistics?.totalStatistic?.fundingRoundsCompleted}`}
						subtitle="Funding Rounds Completed"
						progress="0.80"
						increase="+43%"
						icon={<FundingRoundIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
					/>
				</Box>
				<Box
					gridColumn="span 4"
					bgcolor={colors.primary[400]}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<StatBox
						title={`${statistics?.totalStatistic?.moneyAmountRaised}`}
						subtitle="Money Amount Raised"
						progress="0.80"
						increase="+43%"
						icon={<MoneyIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
					/>
				</Box>
			</Box>
			<Box
				mt="25px"
				p="0 30px"
				display="flex "
				justifyContent="space-between"
				alignItems="center"
				bgcolor={colors.primary[400]}
				height="50px"
			>
				<Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
					Statistics Month
				</Typography>
			</Box>
			<Box bgcolor={colors.primary[400]} mt="25px" p="20px">
				{statistics?.monthsStatistics && statistics?.monthsStatistics.length !== 0 ? (
					<BarChartStatisticsMonths statisticsList={statistics?.monthsStatistics} />
				) : (
					<Box display="flex " justifyContent="center" alignItems="center">
						<Typography variant="h5" color={colors.redAccent[500]}>
							No existing data
						</Typography>
					</Box>
				)}
			</Box>
			<Box
				mt="25px"
				p="0 30px"
				display="flex "
				justifyContent="space-between"
				alignItems="center"
				bgcolor={colors.primary[400]}
				height="50px"
			>
				<Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
					Statistics Month Uprise
				</Typography>
			</Box>
			<Box bgcolor={colors.primary[400]} mt="25px" p="20px">
				{statistics?.monthsUpriseStatistics && statistics?.monthsUpriseStatistics.length !== 0 ? (
					<BarChartStatisticsMonths statisticsList={statistics?.monthsUpriseStatistics} />
				) : (
					<Box display="flex " justifyContent="center" alignItems="center">
						<Typography variant="h5" color={colors.redAccent[500]}>
							No existing data
						</Typography>
					</Box>
				)}
			</Box>
		</Box>
	);
}
