import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { projectFetches } from '../../service';
import { AppDispatch } from '../configureStore';
import { logout } from './configSlice';

export interface Project {
	id?: number;
	firstName: string;
	lastName: string;
	projectName: string;
	email: string;
	website: string;
	twitter: string;
	personalTelegramHandle: string;
	projectTelegramGroup: string;
	onePager: string;
	marketingDeck: string;
	tokenomics: string;
	evidenceOfDevelopment: string;
	collectiveSource: string;
	description: string;
	isVisible: boolean;
}

export interface ProjectState {
	projects: Project[];
	selectedProject: Project | null;
}

const projectInitialState: ProjectState = {
	projects: [],
	selectedProject: null,
};

export const projectSlice = createSlice({
	name: 'project',
	initialState: projectInitialState,
	reducers: {
		add: (state, action: PayloadAction<Project>) => {
			state.projects.push(action.payload);
		},
		list: (state, action: PayloadAction<Project[]>) => {
			state.projects = action.payload;
		},
		update: (state, action: PayloadAction<Project>) => {
			for (const index in state.projects) {
				if (state.projects[index].id === action.payload.id && state.selectedProject) {
					state.projects[index] = action.payload;
					state.selectedProject = action.payload;
				}
			}
		},
		deleteProject: (state, action: PayloadAction<number>) => {
			state.projects = state.projects.filter((t) => t.id !== action.payload);
		},
		selectProject: (state, action: PayloadAction<Project>) => {
			state.selectedProject = action.payload;
		},
	},
});

export const initProjects = () => async (dispatch: AppDispatch) => {
	const listReponse = await projectFetches.listFetch();

	if (listReponse.status === 403) {
		await dispatch(logout());
		return;
	}
	const data = await listReponse.json();

	if (data.status === true) {
		await dispatch(list(data.data));
	}
};

// Action creators are generated for each case reducer function
export const { add, list, update, deleteProject, selectProject } = projectSlice.actions;

export default projectSlice.reducer;
