// prettier-ignore
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { router } from '../Router';
import { useDispatch, useSelector } from '../hooks/hooks';
import { RootState } from '../redux/configureStore';
import { logout } from '../redux/features/configSlice';
import { deleteFeaturedProject, selectFeaturedProject } from '../redux/features/featuredProjectSlice';
import { featuredProjectFetches } from '../service';

export function FeaturedProjectsTable() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	// getting featured project list from redux store
	const featuredProjectsList = useSelector((state: RootState) => state.featuredProjectReducer.featuredProjects);

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	// edit and delete button creation, used in table to view
	const ActionButtonsRenderer = (props: any) => (
		<strong>
			<IconButton
				aria-label="Edit"
				sx={{ color: 'green', ':hover': { bgcolor: '#dcdefc' } }}
				onClick={async (e) => {
					// set selected featured project on edit button click from table
					dispatch(selectFeaturedProject(props.data));
					// go to selected featured project info page
					history.push(router.getFeaturedProject({ id: props.value }).$);
				}}
			>
				<UpdateIcon />
			</IconButton>
			<IconButton
				aria-label="Delete"
				sx={{ color: 'red', ':hover': { bgcolor: '#dcdefc' } }}
				onClick={async (e) => {
					if (props.value) {
						// execute delete request if id exists
						const removeResponse = await featuredProjectFetches.removeFetch(props.value as number);

						if (removeResponse.status === 403) {
							await dispatch(logout());
							return;
						}
						const data = await removeResponse.json();

						if (data.status === true) {
							// delete data from redux store
							await dispatch(deleteFeaturedProject(props.value as number));
						} else {
							setServerErrorMessage(`${data.error}`);
						}
					}
				}}
			>
				<DeleteIcon />
			</IconButton>
		</strong>
	);

	// setting table columns
	const columns = [
		{
			headerName: 'Featured Project Name',
			field: 'name',
			sortable: true,
			filter: true,
			floatingFilter: true,
		},
		{
			headerName: 'Project Overview',
			field: 'projectOverview',
			sortable: true,
			filter: true,
			floatingFilter: true,
		},
		{
			headerName: 'Current Funding Round',
			sortable: true,
			filter: true,
			floatingFilter: true,
			valueGetter: (params: any) => {
				// setting what to see if featured project has current funding round or not
				if (params.data.currentFundingRound) {
					return params.data.currentFundingRound.name;
				} else {
					return 'Not set!';
				}
			},
		},
		{
			headerName: 'Status current round',
			sortable: true,
			filter: true,
			floatingFilter: true,
			valueGetter: (params: any) => {
				// setting status of current funding round
				if (params.data.currentFundingRound) {
					return params.data.currentFundingRound.status;
				} else {
					return 'No current round!';
				}
			},
		},
		{
			headerName: 'Is Visible',
			field: 'isVisible',
			sortable: true,
			filter: true,
			floatingFilter: true,
			cellStyle: (params: any) => {
				if (params.value === true) {
					return { color: 'green' };
				} else {
					return { color: 'red' };
				}
			},
		},
		{
			headerName: 'Is Presentation',
			field: 'isPresentation',
			sortable: true,
			filter: true,
			floatingFilter: true,
			cellStyle: (params: any) => {
				if (params.value === true) {
					return { color: 'green' };
				} else {
					return { color: 'red' };
				}
			},
		},
		{
			headerName: 'Type',
			field: 'type',
			sortable: true,
			filter: true,
			floatingFilter: true,
		},
		{
			headerName: 'Edit / Delete',
			field: 'id',
			cellRenderer: 'actionRenderer',
			cellStyle: { border: 'none' },
		},
	];

	return (
		<AgGridReact
			columnDefs={columns}
			rowData={featuredProjectsList}
			rowSelection="single"
			pagination={true}
			paginationAutoPageSize={true}
			frameworkComponents={{
				actionRenderer: ActionButtonsRenderer,
			}}
		/>
	);
}
